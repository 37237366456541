// Imports
import { Component } from 'react'; 
import { Tooltip } from 'antd';
import { t } from '@lingui/macro';

// Load images
import PoiMapSearchIcon from 'assets/images/poi_marker_search.png'

// Types
import { Poi } from 'ts/interfaces/Poi';

// Helpers
import { getStringFromMeters } from 'helpers/geolocation';
import { StarFilled } from '@ant-design/icons';

// Define props type
type PoiListItemProps = {
    poi: Poi,
    onClick: Function,
    onClickMapSearch?: Function,

    isFavorite?: boolean
}

// Define component
export class PoiListItem extends Component<PoiListItemProps, {}> {
    static defaultProps = { 
        isFavorite: false
    }

    // render will know everything!
    render() {
        const {poi, onClick, onClickMapSearch, isFavorite} = this.props;

        return (
            <div className={`poiListItem`} onClick={() => onClick()}>
                <div className="left">
                    <div className={`imgContainer`}>
                        <img src={poi.partner.bannerImageUrl} alt={poi.partner.name} />
                    </div>
                </div>
                <div className="middle">
                    <span className="name">
                        {isFavorite === true && <StarFilled className="favoriteIcon" />}
                        {poi.name} 
                        {onClickMapSearch != null && 
                            <Tooltip className="searchMapLink" placement="top" title={t`map_zoom_on_map`}>
                                <span onClick={(event) => {event.preventDefault(); event.stopPropagation(); onClickMapSearch()}}>
                                    <img src={PoiMapSearchIcon} alt="search-icon" />
                                </span>
                            </Tooltip>
                        }
                    </span>

                    <div className="category">
                        <span className="label">{poi.category.name}</span>
                        <img src={poi.category.imageUrl} alt="category-icon" />
                    </div>

                    <div className="addressContainer">
                        <span>{poi.address}</span>
                        <span>{poi.zipcode} {poi.city}</span>
                    </div>
                </div>
                <div className="right">
                    {poi.userDistance && poi.userDistance >= 0 && 
                        <div className="distanceContainer">
                            <div className="distance">
                                <span>{getStringFromMeters(poi.userDistance)}</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}