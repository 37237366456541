// Imports
import React from "react";
import { useCookies } from "react-cookie";
import { Buffer } from "buffer";

// Helpers
import { UserPosition } from "helpers/geolocation";

// Constants
import { LOCATION_SEARCH_COOKIE_NAME } from "config/constants";

//
// Define the Context
//
export interface UserLocationContextInterface {}

export const UserLocationContext = React.createContext<UserLocationContextInterface>({} as UserLocationContextInterface);

//
// Define the Provider
//

export interface UserLocationProviderInterface {
  children: React.ReactNode;
}

export function UserLocationProvider({ children }: UserLocationProviderInterface) {
  const [contextValue] = React.useState<UserLocationContextInterface>({});

  return <UserLocationContext.Provider value={contextValue}>{children}</UserLocationContext.Provider>;
}

//
// Define hook
//
export interface UseUserLocation {
  getLastUserPosition: () => UserPosition | null;
}

export function useUserLocation(): UseUserLocation {
  const [cookies] = useCookies([LOCATION_SEARCH_COOKIE_NAME]);

  const getLastUserPosition = (): UserPosition | null => {
    let savedLocation = cookies[LOCATION_SEARCH_COOKIE_NAME];
    if (savedLocation !== undefined && savedLocation != null) {
      savedLocation = Buffer.from(savedLocation, "base64");
	  savedLocation = decodeURIComponent(savedLocation);
      savedLocation = JSON.parse(savedLocation);

      return savedLocation;
    }

    return null;
  };

  return {
    getLastUserPosition,
  };
}
