// Imports
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input } from 'antd';
import { t } from "@lingui/macro"
import { LockOutlined } from '@ant-design/icons';

// Components
import MainLayout from 'components/templates/MainLayout'
import { RoundedButton } from 'components/RoundedButton';

// Constants
import { SiteRoutes } from 'config/constants';

// API 
import { changePassword, login } from 'api/justbipLegacyApi';
import { useAuth } from 'hooks/auth';
import md5 from 'md5';

// Define main state type
type ResetPasswordState = {
    formProcessing: boolean,
    formError: string | null
}

// Main element
function ResetPassword() {
    // Define state variables
    const [state, setState] = React.useState<ResetPasswordState>({ formProcessing: false, formError: null });

    // Define form variable
    const [form] = Form.useForm();

    // Hooks
    const { userId, emailToken } = useParams();
    const navigate = useNavigate();
    let auth = useAuth();

    //
    // Run this at the page mount
    //
    useEffect(() => {
        // If the user is already authenticated, then redirect to user profile
        if(auth.isAuthenticated()) {
            console.log("Redirect to user")
            navigate(SiteRoutes.USER_PROFILE)
        }
    }, [auth, navigate])

    //
    // UI Actions
    //
    const onPressSubmit = () => {
        form.submit();
    }

    const onLoginSubmit = () => {
        setState(prevState => ({ ...prevState, formProcessing: true, formError: null }))

        // Validate the form
        form.validateFields()
			.then((values) => {
                // Call the login API
                changePassword(userId!, emailToken!, values.password, values.confirmPassword).then((apiResult) => {
                    if(apiResult?.success) 
                    { 
                        // Then call the login API
                        login(apiResult.payload.email, md5(values.password)).then((userLogged) => {
                            if(userLogged) 
                            { 
                                // We redirect to user profile
                                navigate(SiteRoutes.USER_PROFILE)
                            }
                            else {
                                setState(prevState => ({ ...prevState, formProcessing: false, formError: t`login_form_error` }))
                            }
                        })
                        .catch(() => {
                            setState(prevState => ({ ...prevState, formProcessing: false, formError: t`login_form_error` }))
                        })
                    }
                    else {
                        setState(prevState => ({ ...prevState, formProcessing: false, formError: t`reset_password_form_error` }))
                    }
                })
                .catch(() => {
                    setState(prevState => ({ ...prevState, formProcessing: false, formError: t`reset_password_form_error` }))
                })
			})
			.catch(() => {
                setState(prevState => ({ ...prevState, formProcessing: false, formError: "Form not valid" }))
            });
    }
    

    //
    // Main Render
    //
    return (
        <MainLayout title={t`reset_password_view_meta_title`} description={t`reset_password_view_meta_description`} withBackground={true}>
            <div className="notConnectedContainer">
                <div className="formContainer">
                    <div className="formContent">
                        <span className="formTitle">{t`reset_password_form_title`}</span>

                        <Form
                            name="login"
                            form={form}
                            className="formContent"
                            wrapperCol={{ span: 24 }}
                            onFinish={onLoginSubmit}
                        >
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input.Password prefix={<LockOutlined />} placeholder={t`global_form_field_password_placeholder`} />
                            </Form.Item>

                            <Form.Item
                                name="confirmPassword"
                                dependencies={['password']}
                                rules={[
                                    { required: true, message: '' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error(t`forgot_password_form_password_not_match_error`));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password prefix={<LockOutlined />} placeholder={t`global_form_field_password_confirm_placeholder`} />
                            </Form.Item>

                            <RoundedButton className={`submitButton`} enabled={!state.formProcessing} loading={state.formProcessing} onClick={() => onPressSubmit()} text={t`reset_password_form_main_cta`} />

                            {state.formError != null && <span className="formError">{state.formError}</span>}
                        </Form>
                    </div>
                    <div className="formFooter">
                        <span>{t`reset_password_form_no_account`}&nbsp;&nbsp;<a href={SiteRoutes.SIGNUP}>{t`reset_password_form_no_account_link`}</a></span>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default ResetPassword;