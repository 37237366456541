// Imports
import React, { useEffect } from "react";
import { t } from "@lingui/macro";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Collapse, Form, Row, Select, Tooltip } from "antd";
import { StarFilled, StarOutlined, CloseCircleOutlined } from "@ant-design/icons";
import slug from "slug";

// Components
import MainLayout from "components/templates/MainLayout";
import { PoiListItem } from "components/PoiListItem";
import { RoundedButton } from "components/RoundedButton";

// Hooks
import { useAuth } from "hooks/auth";

// APIs
import { addFavorite, askAssistance, deleteFavorite, getPoiDetails, getUserFavorites } from "api/justbipLegacyApi";

// Types
import { Poi, PoiPlace } from "ts/interfaces/Poi";
import { User } from "ts/interfaces/User";

// Constants
import { SiteRoutes } from "config/constants";

// Images
import PoiClosedGif from "assets/gif/poi_closed.gif";
import { cancelRunningAssistance, getUserProfile, updateAssistanceVehicle } from "api/justbipApi";

// Define main state type
type PoiState = {
  poi?: Poi;
  loading: boolean;
  user?: User;
  userFavorites: Poi[];

  formPlaceSelected?: PoiPlace;
  formProcessing: boolean;
  formError: string | null;

  showCancelButton: boolean;
};

// Main element
function PoiDetails() {
  // Define form variable
  const [form] = Form.useForm();

  // Define state variables
  const [state, setState] = React.useState<PoiState>({ formProcessing: false, userFavorites: [], formError: null, loading: true, showCancelButton: false });

  // Hooks
  const { poiId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  // Page mount
  useEffect(() => {
    // Retrieve user profile
    getPoiDetails(parseInt(poiId!)).then((poi) => {
      if (poi) setState((prevState) => ({ ...prevState, poi, loading: false }));
      else navigate(SiteRoutes.PLAN);
    });

    getUserProfile().then((user) => {
      if (!user) return;
      setState((prevState) => ({ ...prevState, user }));

      // If there is only one vehicle, automatically select it
      if (user.vehicles.length === 1) {
        form.setFieldsValue({ vehicle: user.vehicles[0].id });
      }
    });

    getUserFavorites().then((favorites) => {
      setState((prevState) => ({ ...prevState, userFavorites: favorites }));
    });
  }, []);

  //
  // Form
  //

  const onPlaceSelection = (value: any) => {
    let subPlace: PoiPlace | undefined;

    if (value !== undefined) {
      subPlace = state.poi?.places.find((p) => p.id === value);
    }

    setState((prevState) => ({ ...prevState, formPlaceSelected: subPlace }));
  };

  const onPressSubmit = () => {
    form.submit();
  };

  const onAssistanceFormSubmit = async () => {
    setState((prevState) => ({ ...prevState, formProcessing: true, formError: null, showCancelButton: false }));

    // Validate the form
    form
      .validateFields()
      .then((values) => {
        getUserProfile().then(async () => {
          const apiResult = await askAssistance(state.poi!, values.place, values.subplace);
          if (!apiResult) setState((prevState) => ({ ...prevState, formProcessing: false, formError: "Unknown error" }));
          else {
            if (apiResult.success && apiResult.payload.id) {
              // If a vehicle is selected, trigger an api call to set the vehicle
              if (values.vehicle) await updateAssistanceVehicle(parseInt(apiResult.payload.id), parseInt(values.vehicle));

              navigate(`/poi/${slug(state.poi!.name)}/${state.poi!.id}/assistance`);
            } else {
              if (apiResult.statusCode === 102)
                setState((prevState) => ({ ...prevState, formProcessing: false, formError: t({ id: `ask_assistance_api_error_${apiResult.statusCode}` }), showCancelButton: true }));
              else setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`ask_assistance_api_error_unknown` }));
            }
          }
        });
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, formProcessing: false, formError: "Unknown error" }));
      });
  };

  //
  // UI Actions
  //
  const onConnectClick = () => {
    navigate(SiteRoutes.LOGIN, { state: { from: location.pathname } });
  };

  const handleAddFavorite = async () => {
    if (!state.poi) return;

    await addFavorite(state.poi.jbcode.toString());
    navigate(0);
  };

  const handleDeleteFavorite = async () => {
    if (!state.poi) return;

    await deleteFavorite(state.poi.jbcode.toString());
    navigate(0);
  };

  const handleCancelAssistanceClick = async () => {
    if (!state.poi || !window.confirm(t`assistance_request_cancel_confirmation`)) return;

    await cancelRunningAssistance();

    // Re-trigger automatically the assistance creation
    form.submit();
  };

  if (!poiId) return null;

  const isFavorite = state.userFavorites.find((uf) => state.poi && uf.jbcode === state.poi.jbcode) ? true : false;

  //
  // Main Render
  //
  return (
    <MainLayout title={state.poi != null ? state.poi?.name : ""} description="" withBackground={true}>
      <div className="floatingMainContainer poiDetailsView">
        <div className="formContainer">
          <div className="formContent">
            <div className="favoriteSwitch">
              {isFavorite ? (
                <button onClick={handleDeleteFavorite} className="favoriteButton">
                  <Tooltip title={t`user_profile_view_favorite_delete_action`}>
                    <StarFilled />
                  </Tooltip>
                </button>
              ) : (
                <button onClick={handleAddFavorite} className="favoriteButton">
                  <Tooltip title={t`user_profile_view_favorite_add_action`}>
                    <StarOutlined />
                  </Tooltip>
                </button>
              )}
            </div>
            {state.poi && <PoiListItem key={`poi_${state.poi.id}`} poi={state.poi} onClick={() => {}} />}

            <Collapse ghost>
              <Collapse.Panel header={t`poi_view_more_info`} key="1">
                <div className="informations">
                  <span className="sectionText">{t`poi_view_info_assistance`}</span>

                  <span className="sectionTitle">{t`poi_view_info_opening_hours`}</span>
                  <span className="sectionText">{t`poi_view_info_opening_hours_desc`}</span>
                  <span className="sectionText">{state.poi?.hoursDetails}</span>

                  {state.poi?.serviceDetails !== "" && (
                    <>
                      <span className="sectionTitle">{t`poi_view_info_services`}</span>
                      <span className="sectionText">{state.poi?.serviceDetails}</span>
                    </>
                  )}
                </div>
              </Collapse.Panel>
            </Collapse>

            <div className="separator"></div>

            {state.poi?.open && auth.isAuthenticated() && (
              <>
                <span className="formTopDescription">{t`poi_view_assistance_form_desc`}</span>

                <Form name="assistanceForm" form={form} className="formContent" wrapperCol={{ span: 24 }} onFinish={onAssistanceFormSubmit}>
                  <Row>
                    <Col xs={24} md={{ span: 16, offset: 4 }}>
                      <Form.Item name="place" rules={[{ required: true, message: "" }]}>
                        <Select placeholder={t`poi_view_assistance_form_place_placeholder`} allowClear showSearch optionFilterProp="children" onChange={onPlaceSelection}>
                          {state.poi?.places.map((place) => {
                            return (
                              <Select.Option key={`place_${place.id}`} value={place.id}>
                                {place.label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      {state.formPlaceSelected && state.formPlaceSelected.subPlaces?.length > 0 && (
                        <Form.Item name="subplace" rules={[{ required: true, message: "" }]}>
                          <Select placeholder={t`poi_view_assistance_form_subplace_placeholder`} allowClear showSearch optionFilterProp="children">
                            {state.formPlaceSelected.subPlaces.map((place) => {
                              return (
                                <Select.Option key={`subplace_${place.id}`} value={place.id}>
                                  {place.label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}

                      {state.formPlaceSelected && state.user && state.user.vehicles.length > 0 && (
                        <Form.Item name="vehicle" rules={[{ required: false, message: "" }]}>
                          <Select placeholder={t`poi_view_assistance_form_car_placeholder`} allowClear showSearch optionFilterProp="children">
                            {state.user.vehicles.map((userVehicle) => {
                              return (
                                <Select.Option key={`vehicle${userVehicle.id}`} value={userVehicle.id}>
                                  {userVehicle.brandName} {userVehicle.modelName} ({userVehicle.numberplate})
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <RoundedButton
                    className={`submitButton`}
                    enabled={!state.formProcessing}
                    loading={state.formProcessing}
                    secondaryColor={true}
                    onClick={() => onPressSubmit()}
                    text={t`poi_view_assistance_form_main_cta`}
                  />

                  {state.formError != null && <span className="formError">{state.formError}</span>}

                  {state.showCancelButton && (
                    <RoundedButton
                      className={`cancelAssistanceButton`}
                      onClick={handleCancelAssistanceClick}
                      text={t`poi_view_assistance_poi_cancel_existing_assistance`}
                      icon={<CloseCircleOutlined />}
                    />
                  )}
                </Form>
              </>
            )}

            {state.poi?.open && auth.isAuthenticated() === false && (
              <>
                <span className="formTopDescription">{t`poi_view_assistance_not_connected_label`}</span>
                <RoundedButton className={`userConnectButton`} secondaryColor={true} onClick={() => onConnectClick()} text={t`poi_view_assistance_not_connected_cta`} />
              </>
            )}

            {state.poi?.open === false && (
              <div className="closedContainer">
                <span className="formTopDescription">{t`poi_view_assistance_poi_closed`}</span>
                <span>{t`poi_view_assistance_poi_closed_desc`}</span>

                <img className="gif" src={PoiClosedGif} alt="poi-closed" />
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default PoiDetails;
