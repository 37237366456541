// Imports
import React, { useEffect, useRef, useState } from 'react'; 
import { Input } from 'antd';

// Load images
import SearchIcon from 'assets/images/ic-search-desktop.svg'

// Define props type
type SearchBarProps = {
    searchText: string,
    onSearch?: (text: string) => void,
    onEnterInput?: (text: string) => void,

    placeholder?: string,
    additionalClasses?: string,
    liveSearch?: boolean
}

// Define state type
type SearchBarState = {
    inputText: string
}

// Define component

export const SearchBar : React.FC<SearchBarProps> = ({searchText, onSearch, onEnterInput, placeholder, additionalClasses = '', liveSearch = true}) => {

    // Declare local references
    const timerRef: { current: NodeJS.Timeout | null } = useRef(null);
    const searchInput = useRef(null);

    // Define state variables
    const [state, setState] = useState<SearchBarState>({ inputText: searchText });

    // Update state if props change
    useEffect(() => {
        setState(prevState => ({ ...prevState, inputText: searchText }))
    }, [searchText])

    // Trigger automatic search when input change
    useEffect(() => {
        handleSearch()
    }, [state.inputText])

    // Handle search interaction
    const handleSearch = () => {
        if(!liveSearch) return;

        if(timerRef.current !== null) clearTimeout(timerRef.current);
        
        timerRef.current = setTimeout(() => { doSearch() }, 300);
    }

    const doSearch = (checkLength : Boolean = true) =>  {
        let searchText = state.inputText

        if(checkLength && searchText.length < 2) {
            searchText = ''
        }

        if(onSearch != null) onSearch(searchText)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnterInput != null)  onEnterInput(state.inputText);
    }

    // Main render
    return (
        <div className={`${additionalClasses}`}>
            <Input.Search 
                ref={searchInput}
                className="search_bar"
                placeholder={placeholder}
                enterButton={ 
                    <img src={SearchIcon} className="ic_search" alt="Search"></img>
                }
                onChange={(e) => {
                    setState(prevState => ({ ...prevState, inputText: e.target.value}))
                }}
                onSearch={(value) => { doSearch(false) }}
                value={state.inputText}

                onKeyDown={handleKeyDown}
            />
        </div>
    )
}