// Imports
import ReactDOM from "react-dom/client";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Import i18n
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { detect, fromNavigator } from "@lingui/detect-locale";
import { fr, en } from "make-plural/plurals";

// Helpers
import { loadConfig } from "helpers/global";

// Custom hooks
import { UserLocationProvider } from "hooks/userLocation";

// Load configuration
const config = loadConfig();

// Load current language
const DEFAULT_LANGUAGE = "en";
const ALLOWED_LANGUAGES = ["fr", "en"];
const DEFAULT_FALLBACK = () => DEFAULT_LANGUAGE;
let language = detect(fromNavigator(), DEFAULT_FALLBACK) ?? DEFAULT_LANGUAGE;
language = language.substring(0, 2).toLowerCase();
if (!ALLOWED_LANGUAGES.includes(language)) language = DEFAULT_LANGUAGE;

// Configure i18n
const catalog = require(`locales/${language}/messages.js`);
i18n.loadLocaleData(language, { plurals: language === "en" ? en : fr });
i18n.load(language, catalog.messages);
i18n.activate(language);

// Render main app
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <I18nProvider i18n={i18n}>
    <CookiesProvider>
      <GoogleReCaptchaProvider reCaptchaKey={config.google.recaptchaClientKey} language={language}>
        <UserLocationProvider>
          <App />
        </UserLocationProvider>
      </GoogleReCaptchaProvider>
    </CookiesProvider>
  </I18nProvider>
);
