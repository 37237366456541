// Imports
import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { t } from "@lingui/macro";
import { useNavigate, useParams } from "react-router-dom";
import slug from "slug";
import moment from "moment";
import { Rate } from "antd";

// Components
import MainLayout from "components/templates/MainLayout";
import { PoiListItem } from "components/PoiListItem";
import { RoundedButton } from "components/RoundedButton";

// APIs
import { getOpenedAssistanceRequest, getPoiDetails, rateAssistanceRequest, retryOpenedAssistanceRequest } from "api/justbipLegacyApi";
import { cancelAssistance } from "api/justbipApi";

// Types
import { Poi } from "ts/interfaces/Poi";
import { AssistanceRequest } from "ts/interfaces/AssistanceRequest";

// Constants
import { SiteRoutes, assistance_request_status } from "config/constants";

// Helpers
import { loadConfig } from "helpers/global";

// Load GIFs
import RequestLoadingGif from "assets/gif/request_loading.gif";
import RequestProcessingGif from "assets/gif/request_processing.gif";
import RequestSuccessGif from "assets/gif/request_success.gif";
import RequestWaitingGif from "assets/gif/request_waiting.gif";
import RequestCompletedGif from "assets/gif/request_completed.gif";
import RequestFailedGif from "assets/gif/request_failed.gif";

// Load configuration
const config = loadConfig();

// Define main state type
type AssistanceState = {
  poi?: Poi;
  assistanceRequest?: AssistanceRequest;
  loading: boolean;
  showRetryButton: boolean;

  ratingScore: number;
  ratingProcessing: boolean;

  assistanceCompleted: boolean;
};

// Main element
function Assistance() {
  // Define state variables
  const [state, setState] = React.useState<AssistanceState>({ loading: true, showRetryButton: false, ratingScore: 2.5, ratingProcessing: false, assistanceCompleted: false });
  const timerRef: { current: NodeJS.Timeout | null } = useRef(null);

  // Hooks
  const { poiId } = useParams();
  const navigate = useNavigate();

  //
  // API
  //
  const refreshAssistanceRequest = useCallback(() => {
    // Retrieve user profile
    getPoiDetails(parseInt(poiId!)).then((poi) => {
      if (poi) {
        setState((prevState) => ({ ...prevState, poi, loading: false }));

        getOpenedAssistanceRequest().then((assistanceRequest) => {
          if (assistanceRequest && assistanceRequest!.poiId === poi.id && assistanceRequest!.isClosed === false) {
            const showRetryButton =
              assistanceRequest!.cycleId === 1 &&
              assistanceRequest!.status === assistance_request_status.SUCCESS &&
              assistanceRequest!.createdAt.add(config.assistanceRetryThreshold, "second").isBefore(moment());

            setState((prevState) => ({ ...prevState, assistanceRequest, showRetryButton, loading: false }));

            // If the assistance has failed, then we clear it out from Front site
            if (assistanceRequest.status === assistance_request_status.FAIL) {
              localStorage.removeItem("justbip_opened_request");

              if (timerRef.current !== null) clearInterval(timerRef.current);
            }
          } else {
            if (timerRef.current !== null) clearInterval(timerRef.current);

            // No opened request, redirect to POI view
            navigate(`/poi/${slug(poi.name)}/${poi.id}`);
          }
        });
      } else {
        navigate(SiteRoutes.PLAN);
      }
    });
  }, [navigate, poiId]);

  //
  // Page mount
  //
  useEffect(() => {
    // Get assistance request data
    refreshAssistanceRequest();

    // Start an automatic refresh
    timerRef.current = setInterval(() => {
      refreshAssistanceRequest();
    }, 1000);

    // On unmount
    return () => {
      if (timerRef.current !== null) clearInterval(timerRef.current);
    };
  }, []);

  //
  // Components render
  //

  const getRequestStatusAnimation = (status: number): ReactNode => {
    if (state.assistanceCompleted) return <img className="statusAnimation" src={RequestCompletedGif} alt="completed-img" />;

    switch (status) {
      case assistance_request_status.SENDING:
        return <img className="statusAnimation" src={RequestLoadingGif} alt="loading-img" />;

      case assistance_request_status.LOADING:
        return <img className="statusAnimation" src={RequestProcessingGif} alt="sending-img" />;

      case assistance_request_status.SUCCESS:
        return <img className="statusAnimation" src={RequestSuccessGif} alt="success-img" />;

      case assistance_request_status.FAIL:
        return <img className="statusAnimation" src={RequestFailedGif} alt="failed-img" />;

      case assistance_request_status.WAITING:
      default:
        return <img className="statusAnimation" src={RequestWaitingGif} alt="waiting-img" />;
    }
  };

  const timelineStyleForStatus = (status: number): string => {
    if (
      (state.assistanceRequest!.status >= status &&
        (status !== assistance_request_status.SUCCESS || (status === assistance_request_status.SUCCESS && state.assistanceRequest!.status !== assistance_request_status.WAITING))) ||
      (state.assistanceRequest!.status === assistance_request_status.SUCCESS && status === assistance_request_status.WAITING) ||
      state.assistanceCompleted
    ) {
      return "stepDone";
    } else {
      return "step";
    }
  };

  const timelineAnimatedStyleForStatus = (status: number): string => {
    if (state.assistanceRequest!.status === status && !state.assistanceCompleted) {
      return "active";
    } else {
      return "";
    }
  };

  //
  // UI Actions
  //

  // Cancel assistance
  const onCancelClick = async () => {
    if (!state.poi || !state.assistanceRequest || !window.confirm(t`assistance_request_cancel_confirmation`)) return;

    await cancelAssistance(state.assistanceRequest.id);

    navigate(`/poi/${slug(state.poi.name)}/${state.poi.id}`);
  };

  // Retry the request
  const onRetryClick = () => {
    retryOpenedAssistanceRequest().then((assistanceRequest) => {});
  };

  // Save rating value
  const onChangeRating = (value: number) => {
    setState((prevState) => ({ ...prevState, ratingScore: value }));
  };

  // Submit the rating score
  const onRatingSubmit = () => {
    setState((prevState) => ({ ...prevState, ratingProcessing: true }));

    rateAssistanceRequest(state.ratingScore).then((ratingResult) => {
      if (ratingResult === true) {
        if (timerRef.current !== null) clearInterval(timerRef.current);

        setState((prevState) => ({ ...prevState, assistanceCompleted: true }));

        // redirect to homepage after few seconds
        setTimeout(() => {
          navigate("/");
        }, 6000);
      } else {
        setState((prevState) => ({ ...prevState, ratingProcessing: false }));
      }
    });
  };

  //
  // Main Render
  //
  return (
    <MainLayout title={state.poi != null ? state.poi?.name : ""} description="" withBackground={true}>
      <div className="floatingMainContainer assistanceView">
        <div className="formContainer">
          <div className="formContent" id="assistanceRequestContainer">
            {state.poi && <PoiListItem key={`poi_${state.poi.id}`} poi={state.poi} onClick={() => {}} />}

            <div className="separator"></div>

            {state.assistanceRequest && (
              <>
                <div
                  className={`requestHeader ${state.assistanceRequest.status === assistance_request_status.SUCCESS ? "requestHeaderSuccess" : ""} ${
                    state.assistanceRequest.status === assistance_request_status.FAIL ? "requestHeaderError" : ""
                  }`}
                >
                  <div className={"anchor"}></div>

                  <div className={"requestHeaderContainer"}>
                    <span className="title">{t({ id: "assistance_request_title", values: { id: state.assistanceRequest.id } })}</span>

                    <div className={"statusContainer"}>
                      {state.assistanceRequest.status !== assistance_request_status.FAIL && (
                        <div className="timeline">
                          <div className={`timelineSmall timelineMargin ${timelineStyleForStatus(assistance_request_status.SENDING)}`}></div>
                          <div className={`timelineSmall timelineMargin ${timelineStyleForStatus(assistance_request_status.LOADING)}`}></div>
                          <div className={`timelineSmall timelineMargin ${timelineStyleForStatus(assistance_request_status.WAITING)}`}></div>
                          <div className={`timelineLarge ${timelineStyleForStatus(assistance_request_status.SUCCESS)}`}></div>
                        </div>
                      )}

                      {state.assistanceRequest.status !== assistance_request_status.FAIL && (
                        <div className={"timelineAnimated"}>
                          <div className={`timelineSmall timelineMargin timelineAnimatedStep ${timelineAnimatedStyleForStatus(assistance_request_status.SENDING)}`}></div>
                          <div className={`timelineSmall timelineMargin timelineAnimatedStep ${timelineAnimatedStyleForStatus(assistance_request_status.LOADING)}`}></div>
                          <div className={`timelineSmall timelineMargin timelineAnimatedStep ${timelineAnimatedStyleForStatus(assistance_request_status.WAITING)}`}></div>
                          <div className={`timelineLarge timelineAnimatedStep ${timelineAnimatedStyleForStatus(assistance_request_status.SUCCESS)}`}></div>
                        </div>
                      )}

                      <span className={`status`}>
                        <span>{t({ id: state.assistanceCompleted ? "assistance_request_status_completed" : `assistance_request_status_${state.assistanceRequest!.status}` })}</span>
                      </span>

                      <div className="statusAnimationContainer">{getRequestStatusAnimation(state.assistanceRequest.status)}</div>

                      {!state.assistanceCompleted && state.showRetryButton && (
                        <div className="retryContainer">
                          <RoundedButton secondaryColor={true} onClick={() => onRetryClick()} text={t`assistance_request_retry_cta`} />
                        </div>
                      )}

                      {!state.assistanceCompleted && !state.showRetryButton && (
                        <div className="cancelContainer">
                          <RoundedButton secondaryColor={true} onClick={() => onCancelClick()} text={t`assistance_request_cancel_cta`} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {state.assistanceRequest.status === assistance_request_status.SUCCESS && state.assistanceCompleted === false && (
                  <div className="closingContainer">
                    <span className="topLabel">{t`assistance_request_closing_desc`}</span>
                    <div className="rating">
                      <Rate allowHalf value={state.ratingScore} onChange={onChangeRating} />
                    </div>
                    <RoundedButton loading={state.ratingProcessing} enabled={!state.ratingProcessing} onClick={() => onRatingSubmit()} text={t`assistance_request_closing_cta`} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Assistance;
