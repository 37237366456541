import axios from "axios";
import { Buffer } from "buffer";
import { getUserAccessToken, setUserAccessToken } from "helpers/auth";

import { loadConfig } from "helpers/global";
import { User, UserLogin, UserVehicle } from "ts/interfaces/User";

// Load configuration
const config = loadConfig();

// Declare an ApiResult result
type ApiResponse = {
  success: boolean;
  data?: any;
  error?: string;
};

//
// Core
//

// Main function to execute a POST API call to retrieve data
const executePost = async (path: string, withAuth: boolean = false, parameters?: object): Promise<ApiResponse> => {
  let apiResult: ApiResponse = { success: false };

  let additionalHeaders = {};
  if (withAuth) {
    const userAccessToken = getUserAccessToken();
    if (userAccessToken) {
      const encoded = Buffer.from(userAccessToken.userId + ":" + userAccessToken.accessToken).toString("base64");
      additionalHeaders = { ...additionalHeaders, Authorization: `Basic ${encoded}` };
    }
  }

  try {
    const response = await axios.post(`${config.justbipApi.url}${path}`, parameters, {
      headers: {
        "Content-Type": "application/json",
        ...additionalHeaders,
      },
    });

    if (response !== null && response.status === 200) {
      apiResult = response.data as ApiResponse;
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      apiResult.error = (err.response.data as ApiResponse)?.error ?? "Unknown error";
    }
  }

  return apiResult;
};

export const getUserProfile = async (): Promise<User | null> => {
  let endpoint = config.justbipApi.endpoints.getUserProfile;

  const result = await executePost(endpoint, true);
  if (result.success) return new User(result.data.user);
  else return null;
};

export const getUserVehicles = async (): Promise<UserVehicle[]> => {
  let endpoint = config.justbipApi.endpoints.getUserVehicles;

  const result = await executePost(endpoint, true);
  if (result.success) return result.data?.user_vehicles as UserVehicle[];
  else return [];
};

export const updateVehicle = async (vehicleId: number, type: number, model: number, color: string, country: string, plateNumber: string): Promise<boolean> => {
  let endpoint = config.justbipApi.endpoints.updateVehicle;
  endpoint = endpoint.replace("{vehicleId}", vehicleId);

  const apiParameters = {
    type,
    model,
    color,
    country,
    numberplate: plateNumber,
  };

  const result = await executePost(endpoint, true, apiParameters);
  return result.success;
};

export const deleteVehicle = async (vehicleId: number): Promise<boolean> => {
  let endpoint = config.justbipApi.endpoints.deleteVehicle;
  endpoint = endpoint.replace("{vehicleId}", vehicleId);

  const result = await executePost(endpoint, true);
  return result.success;
};

export const cancelAssistance = async (assistanceId: number): Promise<boolean> => {
  let endpoint = config.justbipApi.endpoints.cancelAssistance;
  endpoint = endpoint.replace("{assistanceId}", assistanceId);

  const result = await executePost(endpoint, true);
  if (result.success) localStorage.removeItem("justbip_opened_request");
  return result.success;
};

// Endpoint used when we don't have the id of the running assistance
export const cancelRunningAssistance = async (): Promise<boolean> => {
  let endpoint = config.justbipApi.endpoints.cancelAllAssistance;

  const result = await executePost(endpoint, true);
  if (result.success) localStorage.removeItem("justbip_opened_request");
  return result.success;
};

export const updateAssistanceVehicle = async (assistanceId: number, userVehicleId: number): Promise<boolean> => {
  let endpoint = config.justbipApi.endpoints.updateAssistanceVehicle;
  endpoint = endpoint.replace("{assistanceId}", assistanceId);
  endpoint = endpoint.replace("{userVehicleId}", userVehicleId);

  const result = await executePost(endpoint, true);
  return result.success;
};

export const updateUserProfile = async (firstName: string, lastName: string, birthDate: moment.Moment, pmrType: number): Promise<boolean> => {
  let endpoint = config.justbipApi.endpoints.updateUser;

  const apiParameters = {
    firstname: firstName,
    lastname: lastName,
    birthdate: birthDate.format("YYYY-MM-DD"),
    pmr_type: pmrType,
  };

  const result = await executePost(endpoint, true, apiParameters);
  return result.success;
};

export const findPoidsNearAnItinerate = async (poiIds: number[], itineratePath: number[][], distanceInMeters: number): Promise<number[]> => {
  let endpoint = config.justbipApi.endpoints.findPoisNearAnItinerate;

  const apiParameters = {
    poi_ids: poiIds,
    itinerate_path: itineratePath,
    distance_threshold: distanceInMeters,
  };

  const result = await executePost(endpoint, true, apiParameters);
  if (result.success) return (result.data?.poi_ids as number[]) ?? [];
  else return [];
};

export const login = async (email: string, md5Password: string): Promise<UserLogin | null> => {
  let endpoint = config.justbipApi.endpoints.login;

  const apiParameters = {
    email,
    password: md5Password,
  };

  const result = await executePost(endpoint, true, apiParameters);
  if (result.success) {
    const userLogin = result.data as UserLogin;
    setUserAccessToken(userLogin.id.toString(), email, md5Password, userLogin.access_token);
    return userLogin;
  } else return null;
};
