// Define all classes and subclasses needed for Vehicle definition
export class Vehicle {
  id: number;
  brand: string;
  model: string;
  numberPlate: string;

  constructor(apiResult: any) {
    this.id = apiResult.id;
    this.brand = apiResult.brand;
    this.model = apiResult.model;
    this.numberPlate = apiResult.numberplate;
  }
}

export const VEHICLE_TYPES = {
  CAR: 1,
  MOTO: 2,
  SCOOTER: 3,
};

export class VehicleBrand {
  id: number;
  name: string;

  constructor(apiResult: any) {
    this.id = parseInt(apiResult.id);
    this.name = apiResult.name;
  }
}

export class VehicleModel {
  id: number;
  name: string;

  constructor(apiResult: any) {
    this.id = parseInt(apiResult.id);
    this.name = apiResult.name;
  }
}

export class VehicleColor {
  id: number;
  name: string;

  constructor(apiResult: any) {
    this.id = parseInt(apiResult.id);
    this.name = apiResult.label;
  }
}

export class Country {
  code: string;
  label: string;

  constructor(apiResult: any) {
    this.code = apiResult.code;
    this.label = apiResult.label;
  }
}
