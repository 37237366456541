// Imports
import React, { useEffect, useRef } from "react";
import { Map, useMap } from "@vis.gl/react-google-maps";

// Components
import { PoisMapMarker } from "./PoisMapMarker";

// Types
import { Poi } from "ts/interfaces/Poi";

// Load configuration
import { loadConfig } from "helpers/global";

const config = loadConfig();

// Define props type
type PoisMapProps = {
  center: google.maps.LatLngLiteral;
  zoom: number;
  data: Poi[];

  itineraryDirections?: google.maps.DirectionsResult | null;
  poisOnItinerary: number[];
};

export const PoisMap: React.FC<PoisMapProps> = ({ center, zoom, data, itineraryDirections, poisOnItinerary }) => {
  const map = useMap();
  const directionsRenderer = useRef<google.maps.DirectionsRenderer>();

  useEffect(() => {
    if (!map) return;
    console.log("Google maps ready");
    directionsRenderer.current = new google.maps.DirectionsRenderer();

    directionsRenderer.current.setOptions({
      polylineOptions: {
        strokeColor: "#ff69b4",
        strokeOpacity: 1.0,
        strokeWeight: 5,
      },
    });

    directionsRenderer.current.setMap(map);
  }, [map]);

  // If directions is updated, re-render it on the map
  useEffect(() => {
    if (!map || !itineraryDirections || !directionsRenderer.current) return;
    applyDirections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, data, itineraryDirections]);

  const applyDirections = async () => {
    if (!map || !itineraryDirections || !directionsRenderer.current) return;
    directionsRenderer.current.setDirections(itineraryDirections);
  };

  return (
    <Map mapId={config.google.mapsApiId} defaultCenter={center} defaultZoom={zoom}>
      {data.map((poi) => {
        let visible = true;
        if (poisOnItinerary && poisOnItinerary.length > 0) {
          if (!poisOnItinerary.find((id) => poi.id === id)) {
            visible = false;
          }
        }
        return <PoisMapMarker key={`poi_${poi.id}`} title={poi.name} position={{ lat: poi.location!.latitude, lng: poi.location!.longitude }} poi={poi} visible={visible} />;
      })}
    </Map>
  );
};
