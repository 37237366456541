// Imports
import CryptoJS from "crypto-js";

// Helpers
import { loadConfig } from "helpers/global";

// Load configuration
const config = loadConfig();

//
// Core
//

export type UserLegacyApiAccessToken = {
  userId: string;
  mail: string;
  password: string;
  accessToken: string;
};

export const signIn = async (userId: Number, accessToken: string) => {
  await setUserAccessToken(accessToken);
};

export const signOut = async () => {
  setUserAccessToken(null);
  await localStorage.removeItem("justbip_opened_request");
};

export const setUserAccessToken = async (userId?: string | null, mail?: string, password?: string, accessToken?: string) => {
  if (accessToken == null) {
    await localStorage.removeItem("justbip_u");
  } else {
    // Create a JSON user object
    const userData = { userId, mail, password, accessToken };

    // Encrypt it in HmacSHA1
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(userData), config.auth.encryptionKey).toString();

    // Save this in localStorage
    await localStorage.setItem("justbip_u", cipherText);
  }
};

export const getUserAccessToken = (): UserLegacyApiAccessToken | null => {
  const authEntry = localStorage.getItem("justbip_u");
  if (authEntry != null) {
    try {
      // Decrypt the entry
      const bytes = CryptoJS.AES.decrypt(authEntry, config.auth.encryptionKey);
      var originalText = bytes.toString(CryptoJS.enc.Utf8);

      // Parse it
      var userAccessToken = JSON.parse(originalText);

      if (userAccessToken.userId && userAccessToken.accessToken) return userAccessToken as UserLegacyApiAccessToken;
    } catch (exc) {}
  }

  return null;
};
