import moment from "moment";

// Define all classes and subclasses needed for User definition
export class UserVehicle {
  id?: number ;
  type: number;
  brand: number;
  brandName: string;
  model: number;
  modelName: string;
  color: string;
  numberplate_country: string;
  numberplate: string;

  constructor(apiResult: any) {
    this.id = apiResult.id ? parseInt(apiResult.id) : undefined;
    this.type = apiResult.type ? parseInt(apiResult.type) : 0;
    this.brand = apiResult.brand ? parseInt(apiResult.brand) : 0;
    this.brandName = apiResult.brandName;
    this.model = apiResult.model ? parseInt(apiResult.model) : 0;
    this.modelName = apiResult.modelName;
    this.color = apiResult.color;
    this.numberplate_country = apiResult.numberplate_country;
    this.numberplate = apiResult.numberplate;
  }
}
export class User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;

  birthDate: string;
  disabilityType: number;

  vehicles: UserVehicle[] = [];

  constructor(apiResult: any) {
    this.id = apiResult.id;
    this.email = apiResult.email;
    this.firstName = apiResult.firstName;
    this.lastName = apiResult.lastName;
    this.birthDate = apiResult.birthDate ? moment(apiResult.birthDate).format("DD/MM/YYYY") : "";

    this.disabilityType = apiResult.disabilityType ? parseInt(apiResult.disabilityType) : 0;

    apiResult.vehicles.forEach((item: any) => {
      this.vehicles.push(new UserVehicle(item))
    })
  }
}

export type UserLogin = {
  id: number;
  access_token: string;
}