// Imports
import axios from "axios";
import md5 from "md5";
import moment from "moment";
import { t } from "@lingui/macro";

// Helpers
import { loadConfig } from "helpers/global";
import { getUserAccessToken, setUserAccessToken } from "helpers/auth";

// Types
import { Poi } from "ts/interfaces/Poi";
import { User } from "ts/interfaces/User";
import { Country, VehicleBrand, VehicleColor, VehicleModel } from "ts/interfaces/Vehicle";
import { PMRType } from "ts/interfaces/PMRType";
import { AssistanceRequest } from "ts/interfaces/AssistanceRequest";

// Load configuration
const config = loadConfig();

// Declare an ApiResult result
type ApiResult = {
  success: boolean;
  statusCode?: number;
  payload?: any;
  error?: string;
};

//
// Core
//

// Main function to execute a POST API call to retrieve data
const executePost = async (path: string, parameters?: object): Promise<ApiResult> => {
  let apiResult: ApiResult = { success: false };

  try {
    const response = await axios.post(`${config.justbipLegacyApi.url}${path}`, parameters, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    apiResult.statusCode = response.data.status;

    if (response !== null && response.data.status === 0) {
      apiResult.payload = response.data.payload;
      apiResult.success = true;
    }
  } catch (error) {}

  return apiResult;
};

// Retrieve all POIs
export const getPois = async (): Promise<Poi[]> => {
  const apiResult = await executePost(config.justbipLegacyApi.endpoints.poisList);

  const pois: Poi[] = [];
  apiResult.payload.ressources.forEach((entry: any) => {
    pois.push(new Poi(entry));
  });

  return pois;
};

export const getPoiDetails = async (poiId: number): Promise<Poi | null> => {
  let endpoint = config.justbipLegacyApi.endpoints.poiDetails;
  endpoint = endpoint.replace("{poiId}", poiId);

  const apiResult = await executePost(endpoint);

  if (apiResult.success) {
    const poi = new Poi(apiResult.payload);
    return poi;
  }

  return null;
};

//
// User endpoints
//

export const login = async (mail: string, md5Password: string): Promise<User | null> => {
  let endpoint = config.justbipLegacyApi.endpoints.login;
  endpoint = endpoint.replace("{login}", mail);
  endpoint = endpoint.replace("{password}", md5Password);

  const apiResult = await executePost(endpoint);

  if (apiResult.statusCode === 100 || apiResult.statusCode === 101) return null;

  // User credentials are valid, so we can login this user
  try {
    setUserAccessToken(apiResult.payload.id, mail, md5Password, apiResult.payload.token);

    return new User(apiResult.payload);
  } catch (exc) {
    console.log("error");
    console.log(exc);
  }

  return null;
};

export const forgotPassword = async (mail: string): Promise<Boolean | null> => {
  const apiResult = await executePost(config.justbipLegacyApi.endpoints.forgotPassword, { email: mail });

  if (apiResult.statusCode === 100 || apiResult.statusCode === 101) return null;

  return apiResult.success;
};

export const changePassword = async (userId: string, emailToken: string, password: string, confirmPassword: string): Promise<ApiResult | null> => {
  const apiParameters = {
    pseudo: userId,
    token: emailToken,
    new_password: password,
    retype_password: confirmPassword,
  };

  const apiResult = await executePost(config.justbipLegacyApi.endpoints.changePassword, apiParameters);

  if (apiResult.statusCode === 100 || apiResult.statusCode === 101) return null;

  return apiResult;
};

export const register = async (firstName: string, lastName: string, birthDate: moment.Moment, mail: string, password: string, pmrType: number, recaptchaToken: string): Promise<boolean> => {
  const apiParameters = {
    firstname: firstName,
    lastname: lastName,
    dateofbirth: birthDate.format("DD-MM-YYYY"),
    type: pmrType,
    email: mail,
    password: md5(password),
    recaptchaToken: recaptchaToken !== undefined && recaptchaToken !== null && recaptchaToken !== "" ? recaptchaToken : "0",
  };

  const apiResult = await executePost(config.justbipLegacyApi.endpoints.signup, apiParameters);

  // No much to handle here
  // The current API is kind of broken so we can just detect if it's a success or not
  if (apiResult != null) return true;
  else return false;
};

// export const getUserProfile = async (): Promise<User | null> => {
//   // We must use the login endpoint because the current userProfile endpoint is missing multiple attributes (like vehicules)
//   const userAccessToken = getUserAccessToken();
//   if (!userAccessToken) return null;

//   return await login(userAccessToken.mail, userAccessToken.password);
// };

export const getUserFavorites = async (): Promise<Poi[]> => {
  const favorites: Poi[] = [];

  const userAccessToken = getUserAccessToken();
  if (userAccessToken) {
    let endpoint = config.justbipLegacyApi.endpoints.favoritesList;
    endpoint = endpoint.replace("{userId}", userAccessToken.userId);
    endpoint = endpoint.replace("{token}", userAccessToken.accessToken);

    // Format parameters
    const apiParameters = {};

    const apiResult = await executePost(endpoint, apiParameters);

    apiResult?.payload?.ressources?.forEach((entry: any) => {
      favorites.push(new Poi(entry));
    });
  }

  return favorites;
};

export const addFavorite = async (poiId: string): Promise<boolean> => {
  const userAccessToken = getUserAccessToken();
  if (userAccessToken) {
    let endpoint = config.justbipLegacyApi.endpoints.addFavorite;
    endpoint = endpoint.replace("{userId}", userAccessToken.userId);
    endpoint = endpoint.replace("{token}", userAccessToken.accessToken);
    endpoint = endpoint.replace("{poiId}", poiId);

    // Format parameters
    const apiParameters = {};

    const apiResult = await executePost(endpoint, apiParameters);

    if (apiResult.success) return true;
  }

  return false;
};

export const deleteFavorite = async (poiId: string): Promise<boolean> => {
  const userAccessToken = getUserAccessToken();
  if (userAccessToken) {
    let endpoint = config.justbipLegacyApi.endpoints.deleteFavorite;
    endpoint = endpoint.replace("{userId}", userAccessToken.userId);
    endpoint = endpoint.replace("{token}", userAccessToken.accessToken);
    endpoint = endpoint.replace("{poiId}", poiId);

    // Format parameters
    const apiParameters = {};

    const apiResult = await executePost(endpoint, apiParameters);

    if (apiResult.success) return true;
  }

  return false;
};

//
// Vehicles Management
//
export const getVehicleBrands = async (vehicleType: number): Promise<VehicleBrand[] | null> => {
  let endpoint = config.justbipLegacyApi.endpoints.vehicleBrands;
  endpoint = endpoint.replace("{type}", vehicleType);

  const apiResult = await executePost(endpoint);

  const brands: VehicleBrand[] = [];
  apiResult.payload.ressources.forEach((entry: any) => {
    brands.push(new VehicleBrand(entry));
  });

  return brands;
};

export const getVehicleModels = async (vehicleType: number, vehicleBrand: number): Promise<VehicleModel[] | null> => {
  let endpoint = config.justbipLegacyApi.endpoints.vehicleModels;
  endpoint = endpoint.replace("{type}", vehicleType);
  endpoint = endpoint.replace("{brand}", vehicleBrand);

  const apiResult = await executePost(endpoint);

  const brands: VehicleModel[] = [];
  apiResult.payload.ressources.forEach((entry: any) => {
    brands.push(new VehicleModel(entry));
  });

  return brands;
};

export const getVehicleColors = async (): Promise<VehicleColor[]> => {
  const apiResult = await executePost(config.justbipLegacyApi.endpoints.vehicleColors);

  const colors: VehicleColor[] = [];
  apiResult.payload.ressources.forEach((entry: any) => {
    colors.push(new VehicleColor(entry));
  });

  return colors;
};

export const getCountriesList = async (): Promise<Country[]> => {
  const apiResult = await executePost(config.justbipLegacyApi.endpoints.countriesList);

  const countries: Country[] = [];
  apiResult.payload.ressources.forEach((entry: any) => {
    countries.push(new Country(entry));
  });

  return countries;
};

export const addVehicle = async (type: number, model: number, color: string, country: string, plateNumber: string): Promise<boolean> => {
  const userAccessToken = getUserAccessToken();
  if (!userAccessToken) return false;

  let endpoint = config.justbipLegacyApi.endpoints.addVehicle;
  endpoint = endpoint.replace("{userId}", userAccessToken.userId);
  endpoint = endpoint.replace("{token}", userAccessToken.accessToken);

  // Format parameters
  const apiParameters = {
    type,
    model,
    color,
    country,
    numberplate: plateNumber,
  };

  const apiResult = await executePost(endpoint, apiParameters);

  if (apiResult.success) return true;
  else {
    if (apiResult.statusCode === 101 || apiResult.statusCode === 102) throw t({ id: `add_vehicle_modal_api_error_${apiResult.statusCode}` });
    else return false;
  }
};

// Retrieve PMR Types
export const getPMRTypes = async (): Promise<PMRType[]> => {
  const apiResult = await executePost(config.justbipLegacyApi.endpoints.pmrTypesList);

  const types: PMRType[] = [];
  apiResult.payload.PMRList.forEach((entry: any) => {
    types.push(new PMRType(entry));
  });

  return types;
};

//
// Assistance
//
export const askAssistance = async (poi: Poi, place: string, subPlace?: string): Promise<ApiResult | null> => {
  const userAccessToken = getUserAccessToken();
  if (!userAccessToken) return null;

  let endpoint = config.justbipLegacyApi.endpoints.assistanceAsk;
  endpoint = endpoint.replace("{userId}", userAccessToken.userId);
  endpoint = endpoint.replace("{token}", userAccessToken.accessToken);
  endpoint = endpoint.replace("{jbcode}", poi.jbcode);
  endpoint = endpoint.replace("{place}", place);
  endpoint = endpoint.replace("{subPlace}", subPlace ? `${subPlace}/` : "0/");

  const apiResult = await executePost(endpoint);

  if (apiResult.success) {
    const requestAttributes = {
      id: apiResult.payload.id,
      poi: {
        id: poi.id,
        jbcode: poi.jbcode,
        name: poi.name,
      },
    };
    await localStorage.setItem("justbip_opened_request", JSON.stringify(requestAttributes));
  }

  return apiResult;
};

export const getOpenedAssistanceRequest = async (cleanIfNotFound: boolean = false): Promise<AssistanceRequest | null> => {
  const requestData = await localStorage.getItem("justbip_opened_request");

  if (requestData != null) {
    const request = JSON.parse(requestData);

    const userAccessToken = getUserAccessToken();
    if (!userAccessToken) return null;

    let endpoint = config.justbipLegacyApi.endpoints.assistanceCheck;
    endpoint = endpoint.replace("{userId}", userAccessToken.userId);
    endpoint = endpoint.replace("{token}", userAccessToken.accessToken);
    endpoint = endpoint.replace("{assistanceId}", request.id);

    const apiResult = await executePost(endpoint);

    if (apiResult.success) {
      return new AssistanceRequest(apiResult.payload);
    } else if (cleanIfNotFound === true && apiResult.statusCode !== undefined && apiResult.statusCode > 0) {
      // We clean existing opened request
      await localStorage.removeItem("justbip_opened_request");
    }
  }

  return null;
};

export const retryOpenedAssistanceRequest = async (): Promise<AssistanceRequest | null> => {
  const requestData = await localStorage.getItem("justbip_opened_request");

  if (requestData != null) {
    const request = JSON.parse(requestData);

    const userAccessToken = getUserAccessToken();
    if (!userAccessToken) return null;

    let endpoint = config.justbipLegacyApi.endpoints.assistanceRetry;
    endpoint = endpoint.replace("{userId}", userAccessToken.userId);
    endpoint = endpoint.replace("{token}", userAccessToken.accessToken);
    endpoint = endpoint.replace("{assistanceId}", request.id);

    const apiResult = await executePost(endpoint);

    if (apiResult.success) return new AssistanceRequest(apiResult.payload);
  }

  return null;
};

export const rateAssistanceRequest = async (rating: number): Promise<boolean> => {
  const requestData = await localStorage.getItem("justbip_opened_request");

  if (requestData != null) {
    const request = JSON.parse(requestData);

    const userAccessToken = getUserAccessToken();
    if (!userAccessToken) return false;

    let endpoint = config.justbipLegacyApi.endpoints.assistanceValidate;
    endpoint = endpoint.replace("{userId}", userAccessToken.userId);
    endpoint = endpoint.replace("{token}", userAccessToken.accessToken);
    endpoint = endpoint.replace("{assistanceId}", request.id);
    endpoint = endpoint.replace("{rating}", rating);

    const apiResult = await executePost(endpoint);

    if (apiResult.success) {
      // We clean existing opened request
      await localStorage.removeItem("justbip_opened_request");

      return true;
    }
  }

  return false;
};
