// Imports
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { t } from "@lingui/macro";
import { DatePicker, Form, Input, Select } from "antd";
import { TagOutlined } from "@ant-design/icons";

// Components
import MainLayout from "components/templates/MainLayout";
import { RoundedButton } from "components/RoundedButton";

// Constants
import { SiteRoutes } from "config/constants";

// API
import { getPMRTypes } from "api/justbipLegacyApi";

// Types
import { PMRType } from "ts/interfaces/PMRType";
import { getUserProfile, updateUserProfile } from "api/justbipApi";

// Define main state type
type UserProfileState = {
  formProcessing: boolean;
  formError: string | null;

  pmrTypes: PMRType[];
};

// Main element
function EditUserProfile() {
  // Define state variables
  const [state, setState] = React.useState<UserProfileState>({ formProcessing: false, formError: null, pmrTypes: [] });

  // Define form variable
  const [form] = Form.useForm();

  // Use some hooks
  const navigate = useNavigate();

  useEffect(() => {
    // Load user profile and set default values of form
    getUserProfile().then((userProfile) => {
      if (!userProfile) return navigate(SiteRoutes.USER_PROFILE);

      let birthDate = null;
      if (userProfile.birthDate) birthDate = moment(userProfile.birthDate, "DD-MM-YYYY");

      form.setFieldsValue({ firstName: userProfile.firstName, lastName: userProfile.lastName, birthDate: birthDate, pmrType: "" + userProfile.disabilityType });
    });

    // Load PMR Types
    getPMRTypes().then((types) => {
      setState((prevState) => ({ ...prevState, pmrTypes: types }));
    });
  }, []);

  //
  // UI Actions
  //
  const onPressSubmit = () => {
    form.submit();
  };

  //
  // API
  //

  const sendUpdateRequest = () => {
    // Validate the form
    form
      .validateFields()
      .then((values) => {
        // Call the signup API
        updateUserProfile(values.firstName, values.lastName, values.birthDate, values.pmrType)
          .then((updated) => {
            if (updated) {
              // Redirect to user profile
              navigate(SiteRoutes.USER_PROFILE);
            } else {
              setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`edit_profile_form_error` }));
            }
          })
          .catch(() => {
            setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`edit_profile_form_error` }));
          });
      })
      .catch((errorInfo) => {
        setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`edit_profile_form_error` }));
      });
  };

  //
  // Main Render
  //
  return (
    <MainLayout title={t`edit_profile_view_meta_title`} description={t`edit_profile_view_meta_description`} withBackground={true}>
      <div className="notConnectedContainer">
        <div className="formContainer registerForm">
          <div className="formContent">
            <span className="formTitle">{t`edit_profile_form_title`}</span>
            <Form name="update_profile" form={form} className="formContent" wrapperCol={{ span: 24 }} onFinish={sendUpdateRequest}>
              <Form.Item name="firstName" rules={[{ required: true, message: "" }]}>
                <Input prefix={<TagOutlined />} placeholder={t`global_form_field_firstname_placeholder`} />
              </Form.Item>

              <Form.Item name="lastName" rules={[{ required: true, message: "" }]}>
                <Input prefix={<TagOutlined />} placeholder={t`global_form_field_lastname_placeholder`} />
              </Form.Item>

              <Form.Item name="birthDate" rules={[{ required: true, message: "" }]}>
                <DatePicker autoComplete="on" placeholder={t`global_form_field_birthdate_placeholder`} disabledDate={(d) => !d || d.isAfter(moment().subtract(18, "year"))} format="DD/MM/YYYY" />
              </Form.Item>

              <Form.Item name="pmrType" rules={[{ required: true, message: "" }]}>
                <Select placeholder={t`global_form_field_pmr_type_placeholder`} allowClear showSearch optionFilterProp="children">
                  {state.pmrTypes.map((type) => {
                    return (
                      <Select.Option key={`pmr_type_${type.id}`} value={type.id}>
                        {type.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <RoundedButton className={`submitButton`} enabled={!state.formProcessing} loading={state.formProcessing} onClick={() => onPressSubmit()} text={t`edit_profile_form_main_cta`} />

              {state.formError != null && <span className="formError">{state.formError}</span>}
            </Form>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default EditUserProfile;
