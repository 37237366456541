// Imports
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { t } from "@lingui/macro";
import { DatePicker, Form, Input, Select } from "antd";
import { LockOutlined, TagOutlined, UserOutlined } from "@ant-design/icons";
import md5 from "md5";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

// Components
import MainLayout from "components/templates/MainLayout";
import { RoundedButton } from "components/RoundedButton";

// Constants
import { SiteRoutes } from "config/constants";

// API
import { getPMRTypes, register } from "api/justbipLegacyApi";
import { login } from "api/justbipApi";
import { useAuth } from "hooks/auth";

// Types
import { PMRType } from "ts/interfaces/PMRType";

// Define main state type
type SignupState = {
  formProcessing: boolean;
  formError: string | null;

  pmrTypes: PMRType[];

  recaptchaVisible: boolean;
};

// Main element
function Signup() {
  // Define state variables
  const [state, setState] = React.useState<SignupState>({ formProcessing: false, formError: null, recaptchaVisible: false, pmrTypes: [] });

  // Define form variable
  const [form] = Form.useForm();

  // Use some hooks
  const navigate = useNavigate();
  let auth = useAuth();

  //
  // Google reCAPTCHA
  //

  const recatpchaCallback = (token: string) => {
    setState((prevState) => ({ ...prevState, recaptchaVisible: false }));

    // Call register
    sendRegisterRequest(token);
  };

  //
  // Run this at the page mount
  //
  useEffect(() => {
    // If the user is already authenticated, then redirect to user profile
    if (auth.isAuthenticated()) {
      navigate(SiteRoutes.USER_PROFILE);
    }
  }, [auth, navigate]);

  useEffect(() => {
    // Load PMR Types
    getPMRTypes().then((types) => {
      setState((prevState) => ({ ...prevState, pmrTypes: types }));
    });
  }, []);

  //
  // UI Actions
  //
  const onPressSubmit = () => {
    form.submit();
  };

  const onLoginSubmit = () => {
    setState((prevState) => ({ ...prevState, formProcessing: true, formError: null }));

    // Validate the form
    form
      .validateFields()
      .then(() => {
        // Display recaptcha
        setState((prevState) => ({ ...prevState, recaptchaVisible: true }));
      })
      .catch((errorInfo) => {
        setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`register_form_error` }));
      });
  };

  //
  // API
  //

  const sendRegisterRequest = (recaptchaToken: string) => {
    // Validate the form
    form
      .validateFields()
      .then((values) => {
        // Call the signup API
        register(values.firstName, values.lastName, values.birthDate, values.mail, values.password, values.pmrType, recaptchaToken)
          .then((signupSuccess) => {
            if (signupSuccess) {
              // If signup is successfuly, then we login the user
              login(values.mail, md5(values.password))
                .then((userLogged) => {
                  if (userLogged) {
                    // Redirect to user profile
                    navigate(SiteRoutes.USER_PROFILE);
                  } else {
                    setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`login_form_error` }));
                  }
                })
                .catch(() => {
                  setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`login_form_error` }));
                });
            } else {
              setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`register_form_error` }));
            }
          })
          .catch(() => {
            setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`register_form_error` }));
          });
      })
      .catch((errorInfo) => {
        setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`register_form_error` }));
      });
  };

  //
  // Main Render
  //
  return (
    <MainLayout title={t`register_view_meta_title`} description={t`register_view_meta_description`} withBackground={true} withGoogleRecaptcha={true}>
      <div className="notConnectedContainer">
        <div className="formContainer registerForm">
          <div className="formContent">
            <span className="formTitle">{t`register_form_title`}</span>
            <Form name="login" form={form} className="formContent" wrapperCol={{ span: 24 }} onFinish={onLoginSubmit}>
              <Form.Item name="firstName" rules={[{ required: true, message: "" }]}>
                <Input prefix={<TagOutlined />} placeholder={t`global_form_field_firstname_placeholder`} />
              </Form.Item>

              <Form.Item name="lastName" rules={[{ required: true, message: "" }]}>
                <Input prefix={<TagOutlined />} placeholder={t`global_form_field_lastname_placeholder`} />
              </Form.Item>

              <Form.Item name="birthDate" rules={[{ required: true, message: "" }]}>
                <DatePicker autoComplete="on" placeholder={t`global_form_field_birthdate_placeholder`} disabledDate={(d) => !d || d.isAfter(moment().subtract(18, "year"))} format="DD/MM/YYYY" />
              </Form.Item>

              <Form.Item name="pmrType" rules={[{ required: true, message: "" }]}>
                <Select placeholder={t`global_form_field_pmr_type_placeholder`} allowClear showSearch optionFilterProp="children">
                  {state.pmrTypes.map((type) => {
                    return (
                      <Select.Option key={`pmr_type_${type.id}`} value={type.id}>
                        {type.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item name="mail" rules={[{ required: true, type: "email", message: "" }]}>
                <Input prefix={<UserOutlined />} placeholder={t`global_form_field_mail_placeholder`} />
              </Form.Item>

              <Form.Item name="password" rules={[{ required: true, message: "" }]}>
                <Input.Password prefix={<LockOutlined />} placeholder={t`global_form_field_password_placeholder`} />
              </Form.Item>

              <RoundedButton className={`submitButton`} enabled={!state.formProcessing} loading={state.formProcessing} onClick={() => onPressSubmit()} text={t`register_form_main_cta`} />

              {state.recaptchaVisible && <GoogleReCaptcha onVerify={(token) => recatpchaCallback(token)} action={"signup"} />}

              {state.formError != null && <span className="formError">{state.formError}</span>}
            </Form>
          </div>
          <div className="formFooter">
            <span>
              {t`register_form_already_have_account`}&nbsp;&nbsp;<a href={SiteRoutes.LOGIN}>{t`register_form_already_have_account_link`}</a>
            </span>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Signup;
