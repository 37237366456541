// Types
import { Poi } from "ts/interfaces/Poi"

type Coordinates = {
    lat: number;
    lng: number;
}

export type UserPosition = {
	label: string;
	place_id: string;
	location: Coordinates;
}

//
// Core
//

export const distanceBetweenTwoPoints = (lat1:number, lon1:number, lat2:number, lon2:number) => {
	var radlat1 = Math.PI * lat1/180
	var radlat2 = Math.PI * lat2/180
	var theta = lon1-lon2
	var radtheta = Math.PI * theta/180
	var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	if (dist > 1) {
		dist = 1;
	}
	dist = Math.acos(dist)
	dist = dist * 180/Math.PI
	dist = dist * 60 * 1.1515
    dist = dist * 1.609344 // Miles to kilometers
    dist = dist * 1000 // kilometers to meters
	return dist 
}

export const getStringFromMeters = (meters: number) => {
	if(meters >= 1000) {
		return `${(meters/1000).toFixed(1)} km`
	} else {
		return `${meters.toFixed(0)} m`
	}
}

// Sort POIs based on a user position
export const poisWithDistance = (pois: Poi[], position: UserPosition | null) : Poi[] => {

	if( position == null || position!.location.lat == null || position!.location.lng == null ) 
	{
		return pois
	}

	pois = pois.map((p,index) => ({
		...p,
		userDistance: distanceBetweenTwoPoints(position!.location.lat, position!.location.lng, p.location!.latitude, p.location!.longitude)
	}))

	pois.sort(function(p1, p2) {
		if(p1.userDistance != null && p2.userDistance != null) {
			return p1.userDistance - p2.userDistance
		} else {
			return 0
		}
	})
	
	return pois
}