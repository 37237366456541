// Imports
import React, { useEffect, useState } from "react";
import { Modal, Spin, Tooltip } from "antd";
import { t } from "@lingui/macro";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import GooglePlacesAutocomplete, { geocodeByLatLng, geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import { useCookies } from "react-cookie";

// Load images
import GeolocationIcon from "assets/images/geolocation_icon.png";

// Hooks
import { useUserLocation } from "hooks/userLocation";

// Constants
import { LOCATION_SEARCH_COOKIE_NAME } from "config/constants";
import { UserPosition } from "helpers/geolocation";

// Define props type
type UserLocationFormProps = {
  onAddressValidation: (position: UserPosition) => void;
  onCleanAddress?: Function;
};

// Define stats type
type UserLocationFormState = {
  addressLabel: string;
  addressFormVisible: boolean;
  addressInputValue: string;
  selectedGooglePlace?: any;
  selectedPosition?: UserPosition;

  geolocationProcessing: Boolean;
};

export const UserLocationForm: React.FC<UserLocationFormProps> = ({ onAddressValidation, onCleanAddress }) => {
  // Hooks
  const [cookies, setCookie, removeCookie] = useCookies([LOCATION_SEARCH_COOKIE_NAME]);
  const userLocation = useUserLocation();

  // Define state variables
  const [state, setState] = useState<UserLocationFormState>({
    addressLabel: t`user_location_wrapper_no_location_defined`,
    addressFormVisible: false,
    addressInputValue: "",
    geolocationProcessing: false,
  });

  //
  // On component mount
  // Check if we have already a position in cookie and if it's the case, use it
  //
  useEffect(() => {
    const savedUserLocation = userLocation.getLastUserPosition();
    if (savedUserLocation != null) {
      setState((prevState) => ({ ...prevState, addressLabel: savedUserLocation.label }));
    }
  }, []);

  //
  // UI Actions
  //
  const showAddressForm = () => {
    setState((prevState) => ({ ...prevState, addressFormVisible: true }));
  };

  const onInputChange = (e: string) => {
    setState((prevState) => ({ ...prevState, addressInputValue: e }));
  };

  const onSelectPlace = (e: any) => {
    geocodeByPlaceId(e.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setState((prevState) => ({ ...prevState, selectedGooglePlace: e, selectedPosition: { label: e.label, place_id: e.place_id, location: { lat, lng } } }));
      });
  };

  const onSubmitAddress = () => {
    if (state.selectedGooglePlace != null) {
      setState((prevState) => ({ ...prevState, addressLabel: state.selectedGooglePlace.label, addressFormVisible: false, addressInputValue: "" }));
      onAddressValidation(state.selectedPosition!);

      // Set cookie
      let now = new Date();
      let expirationDate = new Date(now.setMinutes(now.getMinutes() + 60 * 24 * 30));
      setCookie(LOCATION_SEARCH_COOKIE_NAME, btoa(encodeURIComponent(JSON.stringify(state.selectedPosition))), { path: "/", expires: expirationDate });
    } else {
      setState((prevState) => ({ ...prevState, addressLabel: t`user_location_wrapper_no_location_defined`, addressFormVisible: false, addressInputValue: "" }));
      if (onCleanAddress) onCleanAddress();

      // Clean cookie
      removeCookie(LOCATION_SEARCH_COOKIE_NAME, { path: "/" });
    }
  };

  //
  // Geolocation callbacks
  //

  // Retrieve user position from browser
  const getUserPosition = () => {
    if ("geolocation" in navigator) {
      setState((prevState) => ({ ...prevState, geolocationProcessing: true }));
      navigator.geolocation.getCurrentPosition(geolocSuccess, geolocError, { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 });
    }
  };

  const geolocSuccess = (position: GeolocationPosition) => {
    if (position) {
      setTimeout(() => {
        const lagLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        geocodeByLatLng(lagLng)
          .then((results) => {
            if (results.length > 0) {
              const place = results[0];
              setState((prevState) => ({ ...prevState, addressLabel: place.formatted_address, addressFormVisible: false, addressInputValue: "", geolocationProcessing: false }));

              let newPosition: UserPosition = { label: place.formatted_address, place_id: place.place_id, location: lagLng };
              onAddressValidation(newPosition);

              // Set cookie
              let now = new Date();
              let expirationDate = new Date(now.setMinutes(now.getMinutes() + 60 * 24 * 30));
              setCookie(LOCATION_SEARCH_COOKIE_NAME, btoa(encodeURIComponent(JSON.stringify(newPosition))), { path: "/", expires: expirationDate });
            }
          })
          .catch((error) => console.error(error));
      }, 500);
    }
  };

  const geolocError = () => {
    setState((prevState) => ({ ...prevState, geolocationProcessing: false }));
  };

  //
  // Render
  //
  return (
    <div className="userLocationWrapper">
      <div className="userLocationLabels">
        <span className="desc">{t`user_location_wrapper_title`}</span>
        <button onClick={() => showAddressForm()} className="selector">
          <span>{state.addressLabel}</span>&nbsp;&nbsp;
          <DownOutlined />
        </button>
      </div>

      <Modal
        title={t`user_location_address_modal_title`}
        visible={state.addressFormVisible}
        className="addressFormModal"
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t`user_location_address_modal_cta`}
        onOk={() => onSubmitAddress()}
        okButtonProps={{
          className: "modalSubmitButton",
        }}
        onCancel={() => setState((prevState) => ({ ...prevState, addressFormVisible: false }))}
        bodyStyle={{ padding: 16, textAlign: "left" }}
        centered
      >
        <span className="desc">{t`user_location_address_modal_desc`}</span>

        <div className="inputsContainer">
          <GooglePlacesAutocomplete
            apiOptions={{
              language: "fr",
              region: "fr",
            }}
            selectProps={{
              placeholder: t`user_location_address_modal_input_placeholder`,

              onChange: (e: any) => onSelectPlace(e),

              // inputValue: state.addressInputValue,
              onInputChange: (e: any) => onInputChange(e),

              menuIsOpen: state.addressInputValue.length > 0,
            }}
            minLengthAutocomplete={1}
          />
          <Tooltip className="userPosition" title={t`user_location_address_modal_geolocation_tooltip`}>
            <button onClick={() => getUserPosition()}>
              {!state.geolocationProcessing && <img src={GeolocationIcon} alt="user location" />}
              {state.geolocationProcessing && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            </button>
          </Tooltip>
        </div>
      </Modal>
    </div>
  );
};
