// Imports
import React, { Component } from "react";
import { CarOutlined, DeleteOutlined } from "@ant-design/icons";

// Types
import { UserVehicle } from "ts/interfaces/User";
// Define props type
type UserVehicleItemProps = {
  vehicle: UserVehicle;
  onClick: Function;
  onDelete?: () => void;
};

// Define component
export class UserVehicleItem extends Component<UserVehicleItemProps, {}> {
  static defaultProps = {};

  // render will know everything!
  render() {
    const { vehicle, onClick, onDelete } = this.props;

    return (
      <div className={`userVehicleItem`} onClick={() => onClick()}>
        <div className="left">
          <CarOutlined />
        </div>
        <div className="middle">
          <span className="name">
            {vehicle.brandName} {vehicle.modelName}
          </span>

          <div className="category">
            <span className="label">{vehicle.numberplate}</span>
          </div>
        </div>

        {onDelete && (
          <button onClick={(e) => {e.stopPropagation(); onDelete()}} className="deleteVehicle">
            <DeleteOutlined />
          </button>
        )}
      </div>
    );
  }
}
