// Imports
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// Components 
import RequireAuth from 'components/routing/RequireAuth'

// Pages
import Home from 'pages/Home'
import Plan from 'pages/Plan'
import Poi from 'pages/Poi'
import Assistance from 'pages/Assistance'

import Login from 'pages/auth/Login'
import Signup from 'pages/auth/Signup'
import ForgotPassword from 'pages/auth/ForgotPassword'
import ResetPassword from 'pages/auth/ResetPassword'
import UserProfile from 'pages/connected/UserProfile'
import EditUserProfile from 'pages/connected/EditUserProfile'

import PageNotFound from 'pages/errors/PageNotFound'

// Import style 
import 'assets/scss/main.style.scss'

// Config
import { SiteRoutes } from 'config/constants'

//
// Main render
//
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={SiteRoutes.HOME} element={ <Home /> } />
        <Route path={SiteRoutes.PLAN} element={ <Plan /> } />
        <Route path={SiteRoutes.POI} element={ <Poi /> } />
        <Route path={SiteRoutes.ASSISTANCE} element={ <Assistance /> } />

        <Route path={SiteRoutes.LOGIN} element={ <Login /> } />
        <Route path={SiteRoutes.SIGNUP} element={ <Signup /> } />
        <Route path={SiteRoutes.FORGOT_PASSWORD} element={ <ForgotPassword /> } />
        <Route path={SiteRoutes.RESET_PASSWORD} element={ <ResetPassword /> } />

        <Route path={SiteRoutes.USER_PROFILE} element={<RequireAuth><UserProfile /></RequireAuth>} />
        <Route path={SiteRoutes.EDIT_USER_PROFILE} element={<RequireAuth><EditUserProfile /></RequireAuth>} />

        <Route path="*" element={ <PageNotFound />} /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
