// Imports
import React, { useEffect } from "react";
import { t } from "@lingui/macro";
import { useNavigate } from "react-router-dom";
import { CalendarOutlined, LoadingOutlined, LogoutOutlined, DeleteOutlined, MailOutlined, PlusCircleOutlined, UserOutlined, EditOutlined } from "@ant-design/icons";
import { Row, Spin, Tooltip } from "antd";
import slug from "slug";

// APIs
import { deleteFavorite, getUserFavorites } from "api/justbipLegacyApi";
import { signOut } from "helpers/auth";
import { getUserProfile, deleteVehicle } from "api/justbipApi";

// Components
import MainLayout from "components/templates/MainLayout";
import { RoundedButton } from "components/RoundedButton";
import { VehicleModal } from "components/modals/VehicleModal";
import { UserVehicleItem } from "components/UserVehicleItem";
import { PoiListItem } from "components/PoiListItem";

// Types
import { User, UserVehicle } from "ts/interfaces/User";
import { Poi } from "ts/interfaces/Poi";
import { SiteRoutes } from "config/constants";

// Define main state type
type UserProfileState = {
  user?: User;
  userFavorites: Poi[];
  loading: boolean;

  isVehicleModalVisible: boolean;
  vehicleEdited: UserVehicle | null;
};

// Main element
function UserProfile() {
  // Define state variables
  const [state, setState] = React.useState<UserProfileState>({ loading: true, userFavorites: [], isVehicleModalVisible: false, vehicleEdited: null });

  // Use some hooks
  const navigate = useNavigate();

  // Page mount
  useEffect(() => {
    // Retrieve user profile
    getUserProfile().then(async (user) => {
      if (user) {
        setState((prevState) => ({ ...prevState, user }));

        getUserFavorites().then((favorites) => {
          setState((prevState) => ({ ...prevState, userFavorites: favorites, loading: false }));
        });
      } else {
        signOut();
        navigate(SiteRoutes.LOGIN);
      }
    });
  }, [navigate]);

  //
  // UI Actions
  //

  // Logout the user and redirect to home page
  const onLogoutClick = () => {
    signOut();
    navigate(SiteRoutes.HOME);
  };

  //
  // Vehicles management related functions
  //
  const openAddVehicleModal = () => {
    setState((prevState) => ({ ...prevState, isVehicleModalVisible: true }));
  };

  const openEditVehicleModal = (userVehicle: UserVehicle) => {
    setState((prevState) => ({ ...prevState, isVehicleModalVisible: true, vehicleEdited: userVehicle }));
  };

  const closeVehicleModal = () => {
    setState((prevState) => ({ ...prevState, isVehicleModalVisible: false, vehicleEdited: null }));
  };

  const onVehicleUpdate = () => {
    // Reload the whole page
    navigate(0);
  };

  const handleDeleteVehicle = async (vehicle: UserVehicle) => {
    if (!vehicle.id) return;

    if (!window.confirm(t`delete_vehicle_confirm`)) return;

    await deleteVehicle(vehicle.id);
    navigate(0);
  };

  const handleDeleteFavorite = async (poi: Poi) => {
    await deleteFavorite(poi.jbcode.toString());
    navigate(0);
  };

  const onPoiClick = (poi: Poi) => {
    navigate(`/poi/${slug(poi.name)}/${poi.id}`);
  };

  //
  // Main Render
  //
  return (
    <MainLayout title={t`user_profile_view_meta_title`} description={t`user_profile_view_meta_description`} withBackground={true}>
      <div className="accountProfile">
        <div className="formContainer">
          <div className="formContent">
            <span className="formTitle">
              {t`user_profile_view_title`}
              <div className="editAction" onClick={() => navigate(SiteRoutes.EDIT_USER_PROFILE)}>
                <EditOutlined />
              </div>
            </span>
            {state.loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />}
            {!state.loading && (
              <>
                <div className="profileInfo">
                  <div className="item">
                    <MailOutlined />
                    <span>{state.user?.email}</span>
                  </div>
                  <div className="item">
                    <UserOutlined />
                    <span>
                      {state.user?.firstName} {state.user?.lastName}
                    </span>
                  </div>
                  <div className="item">
                    <CalendarOutlined />
                    <span>{state.user?.birthDate}</span>
                  </div>
                </div>
                <div className="separator" />
                <span className="formTitle">{t`user_profile_view_vehicles_list`}</span>
                <div className="vehiclesList">
                  {state.user?.vehicles.length === 0 && <span className="empty">{t`user_profile_view_vehicles_list_empty`}</span>}

                  <Row className="vehiclesList">
                    {state.user?.vehicles.map((vehicle) => {
                      return <UserVehicleItem key={`user_car_${vehicle.id}`} vehicle={vehicle} onClick={() => openEditVehicleModal(vehicle)} onDelete={() => handleDeleteVehicle(vehicle)} />;
                    })}
                  </Row>

                  <RoundedButton className={`addButton`} icon={<PlusCircleOutlined />} text={t`user_profile_view_vehicle_add_new`} onClick={openAddVehicleModal} />
                </div>

                {state.userFavorites.length > 0 && (
                  <>
                    <div className="separator" />
                    <span className="formTitle">{t`user_profile_view_favorites_list`}</span>
                    <div className="favoritesList">
                      {state.userFavorites.map((poi, index) => (
                        <div className="item" key={`favorite_${index}`}>
                          <button onClick={() => handleDeleteFavorite(poi)} className="deleteFavoriteButton">
                            <Tooltip title={t`user_profile_view_favorite_delete_action`}>
                              <DeleteOutlined />
                            </Tooltip>
                          </button>
                          <PoiListItem poi={poi} onClick={() => onPoiClick(poi)} />
                        </div>
                      ))}
                    </div>
                  </>
                )}

                <button onClick={onLogoutClick} className="logoutButton">
                  <Tooltip title={t`user_profile_view_logout_action`}>
                    <LogoutOutlined />
                  </Tooltip>
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <VehicleModal visible={state.isVehicleModalVisible} onClose={() => closeVehicleModal()} onUpdate={onVehicleUpdate} vehicleEdited={state.vehicleEdited ?? undefined} />
    </MainLayout>
  );
}

export default UserProfile;
