/* Imports */
import React, { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { Layout, Menu, Col, Row } from "antd";
import { AlertOutlined, MenuOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { MenuInfo } from "rc-menu/lib/interface";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import slug from "slug";
import { APIProvider } from "@vis.gl/react-google-maps";

// Components
import { SimulateItineraryModal } from "components/modals/SimulateItineraryModal";

// Hooks
import { useAuth } from "hooks/auth";

// Constants
import { SiteRoutes } from "config/constants";

// API
import { getOpenedAssistanceRequest } from "api/justbipLegacyApi";

// Load images
import JustbipLogo from "assets/images/justbip_logo.svg";

// Load PDFs
import CGUPdf from "assets/pdf/cgu_justbip.pdf";
import ConfidentialitePdf from "assets/pdf/confidentialite_justbip.pdf";

// Load configuration
import { loadConfig } from "helpers/global";
const config = loadConfig();

// Define main props types
type MainLayoutProps = {
  children: ReactNode;
  title: string;
  description: string;

  withBackground?: Boolean;
  withGoogleRecaptcha?: Boolean;
};

// Define state type
type MenuItemType = {
  key: string;
  label: string;
  path: string;
  className?: string;
  icon?: ReactNode;
  onClick?: () => void;
};

type MainLayoutState = {
  menuItems: MenuItemType[];
  displaySimulateItinerary: boolean;
};

// Main element
const MainLayout: React.FunctionComponent<MainLayoutProps> = ({ children, title, description, withBackground = false, withGoogleRecaptcha = false }) => {
  // Load needed hooks
  const navigate = useNavigate();
  const auth = useAuth();

  // Define state variables
  const [state, setState] = useState<MainLayoutState>({ menuItems: [], displaySimulateItinerary: false });

  // Page mount
  useEffect(() => {
    let menuItems: MenuItemType[] = [
      { key: "plan", label: t`menu_item_plan`, path: SiteRoutes.PLAN },
      { key: "itinerary_simulate", label: t`map_itinerary_button`, path: "", onClick: () => setState((prevState) => ({ ...prevState, displaySimulateItinerary: true })) },
    ];

    if (auth.isAuthenticated()) {
      menuItems.push({ key: "profile", label: t`menu_item_user_profile`, path: SiteRoutes.USER_PROFILE });
    } else {
      menuItems.push({ key: "profile", label: t`menu_item_login`, path: SiteRoutes.LOGIN });
    }

    // Check if we have an opened assistance request
    const requestData = localStorage.getItem("justbip_opened_request");
    if (requestData != null) {
      getOpenedAssistanceRequest(true).then((assistanceRequest) => {
        if (assistanceRequest) {
          const assistanceRequestCookieeData = JSON.parse(requestData);

          let assistancePath = SiteRoutes.ASSISTANCE;
          assistancePath = assistancePath.replace(":poiName", slug(assistanceRequestCookieeData.poi.name));
          assistancePath = assistancePath.replace(":poiId", assistanceRequestCookieeData.poi.id);
          menuItems.unshift({ key: "assistance", label: t`menu_item_assistance_opened`, className: "warning flash", icon: <AlertOutlined />, path: assistancePath });
        }

        setState((prevState) => ({ ...prevState, menuItems }));
      });
    } else {
      setState((prevState) => ({ ...prevState, menuItems }));
    }
  }, []);

  useLayoutEffect(() => {
    // Show google recaptcha when it's needed
    if (withGoogleRecaptcha === true) {
      setTimeout(() => {
        const captcha: any = document.getElementsByClassName("grecaptcha-badge")[0];
        if (captcha) {
          captcha.style.visibility = "visible";
        }
      }, 500);
    }
  }, [withGoogleRecaptcha]);

  //
  // UI Actions
  //
  const onMenuClick = (menuEntry: MenuInfo) => {
    const menuItem = state.menuItems.find((m) => m!.key === menuEntry?.key);
    if (menuItem != null) {
      if (menuItem.onClick) menuItem.onClick();
      else navigate(menuItem.path);
    }
  };

  //
  // Main Render
  //

  const assistanceRequestMenuItem = state.menuItems?.find((m) => m.key === "assistance");

  return (
    <Layout className={`layout${withBackground ? " withBackground" : ""}${withGoogleRecaptcha ? " withGoogleRecaptcha" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} | JustBip</title>
        <meta name="description" content={description} />
      </Helmet>

      <Layout.Header className="main_header">
        <Col
          className="header_menu"
          xxl={{ span: 16, offset: 4 }}
          xl={{ span: 20, offset: 2 }}
          lg={{ span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
        >
          <Row gutter={10} align="middle" justify="space-between">
            <Col xs={{ span: 18 }} sm={10} md={{ span: 6 }}>
              <Row gutter={10} align="middle" justify="start">
                <Col>
                  <a href="/">
                    <img src={JustbipLogo} className="logo" alt="Justbip" />
                  </a>
                </Col>
              </Row>
            </Col>
            {assistanceRequestMenuItem && (
              <Col xs={{ span: 3 }} sm={{ span: 0 }}>
                <div className={`ant-menu-item ${assistanceRequestMenuItem.className}`} onClick={() => navigate(assistanceRequestMenuItem.path)}>
                  {assistanceRequestMenuItem.icon}
                </div>
              </Col>
            )}
            <Col xs={{ span: 3 }} sm={14} md={{ span: 18 }} id="header_menu_links">
              <Menu mode="horizontal" overflowedIndicator={<MenuOutlined />} items={state.menuItems} style={{ justifyContent: "flex-end" }} onClick={(key) => onMenuClick(key)} />
            </Col>
          </Row>
        </Col>
      </Layout.Header>
      <Layout.Content id="mainBody">
        {children}
        <SimulateItineraryModal
          visible={state.displaySimulateItinerary}
          pois={[]}
          onClose={() => setState((prevState) => ({ ...prevState, displaySimulateItinerary: false }))}
          onSimulationValidation={(directionsResult, poiIds) => {
            localStorage.setItem("justbip_preloaded_directions", JSON.stringify({ result: directionsResult, poiIds }));
            navigate(SiteRoutes.PLAN);
          }}
        />
      </Layout.Content>
      <Layout.Footer style={{ textAlign: "center" }}>
        <Col
          className="header_menu"
          xxl={{ span: 16, offset: 4 }}
          xl={{ span: 20, offset: 2 }}
          lg={{ span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
        >
          <div className="links">
            <ul>
              <li>
                <a href="/">{t`site_name`}</a>
              </li>
              <li>
                <a href="mailto:info@justbip.com">{t`footer_contact`}</a>
              </li>
              <li>
                <a href={CGUPdf}>{t`footer_cgu`}</a>
              </li>
              <li>
                <a href={ConfidentialitePdf}>{t`footer_privacy`}</a>
              </li>
              <li>
                <a href="https://smilee.fr/">Smilee</a>
              </li>
            </ul>
            <span className="copyright">© Justbip {new Date().getFullYear()}</span>
          </div>
        </Col>
        <APIProvider apiKey={config.google.mapsApiKey} libraries={["places"]} />
      </Layout.Footer>
    </Layout>
  );
};

export default MainLayout;
