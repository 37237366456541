/* eslint-disable @typescript-eslint/no-redeclare */
// Imports
import React from 'react';

// Helpers
import {getUserAccessToken } from 'helpers/auth'

//
// Define the Context
//
export interface AuthContext {}

export const AuthContext = React.createContext<AuthContext>({} as AuthContext);

//
// Define the Provider
//

export interface AuthProvider {
	children: React.ReactNode;
}

export function AuthProvider({
	children,
}: AuthProvider) 
{
	const [contextValue] = React.useState<AuthContext>({ });
    
	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	);
}

//
// Define hook
//
export interface UseAuth {
	isAuthenticated: () => boolean
}

export function useAuth(): UseAuth {

	function isAuthenticated() {
		return getUserAccessToken() !== null ? true : false
	}

	return {
		isAuthenticated,
	};
}