// Imports
import { useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { Form, Input, Modal, Select } from "antd";

// APIs
import { addVehicle, getCountriesList, getVehicleBrands, getVehicleColors, getVehicleModels } from "api/justbipLegacyApi";

// Types
import { Country, VehicleBrand, VehicleColor, VehicleModel, VEHICLE_TYPES } from "ts/interfaces/Vehicle";
import { UserVehicle } from "ts/interfaces/User";
import { updateVehicle } from "api/justbipApi";

// Define props type
type VehicleModalProps = {
  visible: boolean;
  onClose: Function;
  onUpdate: Function;
  vehicleEdited?: UserVehicle;
};

// Define state type
type VehicleModalState = {
  vehicleBrands: VehicleBrand[];
  vehicleModels: VehicleModel[];
  vehicleColors: VehicleColor[];
  countries: Country[];

  vehicleType?: number;
  vehicleBrand?: number;
  vehicleModel?: number;

  formError?: string | null;
};

// Define component

export const VehicleModal: React.FC<VehicleModalProps> = ({ visible = false, onClose, onUpdate, vehicleEdited }) => {
  // Define state variables
  const [state, setState] = useState<VehicleModalState>({ vehicleBrands: [], vehicleModels: [], vehicleColors: [], countries: [] });

  // Define form variable
  const [form] = Form.useForm();

  // Page mount
  useEffect(() => {
    // Retrieve vehicle brands
    getVehicleColors().then((colors) => {
      setState((prevState) => ({ ...prevState, vehicleColors: colors }));
    });

    // Retrieve countries
    getCountriesList().then((countries) => {
      setState((prevState) => ({ ...prevState, countries }));
    });
  }, []);

  // When vehicleType is changed
  useEffect(() => {
    // Retrieve vehicle brands
    getVehicleBrands(state.vehicleType!).then((brands) => {
      setState((prevState) => ({ ...prevState, vehicleBrands: brands! }));
    });
  }, [state.vehicleType]);

  // When vehicleBrand is changed
  useEffect(() => {
    // Retrieve vehicle brands
    getVehicleModels(state.vehicleType!, state.vehicleBrand!).then((models) => {
      setState((prevState) => ({ ...prevState, vehicleModels: models! }));
    });
  }, [state.vehicleType, state.vehicleBrand]);

  // If we are in a vehicle edition, set default value
  useEffect(() => {
    if (!vehicleEdited) return;

    initForm(vehicleEdited);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleEdited]);

  const initForm = async (userVehicle: UserVehicle) => {
    // 1) Retrieve brands related to the vehicle type
    const brands = await getVehicleBrands(userVehicle.type);
    setState((prevState) => ({ ...prevState, vehicleBrands: brands! }));

    // 2) Retrieve models related to this brand
    const models = await getVehicleModels(userVehicle.type, userVehicle.brand);
    setState((prevState) => ({ ...prevState, vehicleModels: models! }));

    // 3) Update form values
    form.setFieldsValue({
      type: userVehicle.type,
      brand: userVehicle.brand,
      model: userVehicle.model,
      color: userVehicle.color,
      immatCountry: userVehicle.numberplate_country,
      plateNumber: userVehicle.numberplate,
    });

    // 3) Update state
    setState((prevState) => ({ ...prevState, vehicleType: userVehicle.type, vehicleBrand: userVehicle.brand, vehicleModel: userVehicle.model }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //
  // UI Actions
  //

  // On vehicle type change, we reset the brand because brands list will be reloaded
  const onVehicleTypeChange = (value: any) => {
    form.setFieldsValue({ brand: undefined, model: undefined});
    setState((prevState) => ({ ...prevState, vehicleType: value, vehicleBrand: undefined, vehicleModel: undefined }));
  };

  // On vehicle type change, we reset the brand because brands list will be reloaded
  const onVehicleBrandChange = (value: any) => {
    form.setFieldsValue({ model: undefined });
    setState((prevState) => ({ ...prevState, vehicleBrand: value, vehicleModel: undefined }));
  };

  const onVehicleModelChange = (value: any) => {
    setState((prevState) => ({ ...prevState, vehicleModel: value }));
  };

  const onSubmit = () => {
    form.submit();
  };

  const resetForm = () => {
    form.resetFields();
    setState((prevState) => ({ ...prevState, vehicleType: undefined, vehicleBrand: undefined, vehicleModel: undefined }));
  };

  const onVehicleFormSubmit = () => {
    setState((prevState) => ({ ...prevState, formProcessing: true, formError: null }));

    // Validate the form
    form
      .validateFields()
      .then((values) => {
        let actionToExecute = vehicleEdited
          ? updateVehicle(vehicleEdited.id!, values.type, values.model, values.color, values.immatCountry, values.plateNumber)
          : addVehicle(values.type, values.model, values.color, values.immatCountry, values.plateNumber);

        actionToExecute
          .then((success) => {
            if (!success) {
              setState((prevState) => ({ ...prevState, formProcessing: false, formError: vehicleEdited ? t`edit_vehicle_modal_form_error` : t`add_vehicle_modal_form_error` }));
            } else {
              // Call the callback passed in props
              onUpdate();

              resetForm();
            }
          })
          .catch((errorInfo) => {
            setState((prevState) => ({ ...prevState, formProcessing: false, formError: errorInfo }));
          });
      })
      .catch((errorInfo) => {
        setState((prevState) => ({ ...prevState, formProcessing: false, formError: vehicleEdited ? t`edit_vehicle_modal_form_error` : t`add_vehicle_modal_form_error` }));
      });
  };

  //
  // Main render
  //
  return (
    <Modal
      visible={visible}
      title={vehicleEdited ? t`edit_vehicle_modal_title` : t`add_vehicle_modal_title`}
      className="vehicleFormModal"
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        resetForm();
        onClose();
      }}
      okText={t`add_vehicle_modal_title` ? t`edit_vehicle_modal_submit_cta` : t`add_vehicle_modal_submit_cta`}
      onOk={() => onSubmit()}
      okButtonProps={{
        className: "modalSubmitButton",
      }}
    >
      <Form name="login" form={form} className="formContent" wrapperCol={{ span: 24 }} onFinish={onVehicleFormSubmit}>
        <Form.Item name="type" rules={[{ required: true, message: "" }]}>
          <Select placeholder={t`add_vehicle_modal_field_vehicle_type_placeholder`} allowClear onChange={onVehicleTypeChange} value={state.vehicleType} optionFilterProp="children">
            {[VEHICLE_TYPES.CAR, VEHICLE_TYPES.MOTO, VEHICLE_TYPES.SCOOTER].map((entry) => {
              return (
                <Select.Option key={`type_${entry}`} value={entry}>
                  {t({ id: `vehicle_type_${entry}` })}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        {state.vehicleType && (
          <Form.Item name="brand" rules={[{ required: true, message: "" }]}>
            <Select placeholder={t`add_vehicle_modal_field_vehicle_brand_placeholder`} allowClear showSearch onChange={onVehicleBrandChange} optionFilterProp="children">
              {state.vehicleBrands.map((vehicleBrand) => {
                return (
                  <Select.Option key={`brand_${vehicleBrand.id}`} value={vehicleBrand.id}>
                    {vehicleBrand.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        {state.vehicleType && state.vehicleBrand && (
          <Form.Item name="model" rules={[{ required: true, message: "" }]}>
            <Select placeholder={t`add_vehicle_modal_field_vehicle_model_placeholder`} allowClear showSearch onChange={onVehicleModelChange} optionFilterProp="children">
              {state.vehicleModels.map((model) => {
                return (
                  <Select.Option key={`brand_${model.id}`} value={model.id}>
                    {model.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        {state.vehicleType && state.vehicleBrand && state.vehicleModel && (
          <>
            <Form.Item name="color" rules={[{ required: true, message: "" }]}>
              <Select placeholder={t`add_vehicle_modal_field_vehicle_color_placeholder`} allowClear showSearch optionFilterProp="children">
                {state.vehicleColors.map((color) => {
                  return (
                    <Select.Option key={`color_${color.id}`} value={color.id}>
                      {color.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <div className="separator" />

            <span className="immatriculation">{t`add_vehicle_modal_field_immat_section_title`}</span>

            <Form.Item name="immatCountry" rules={[{ required: true, message: "" }]}>
              <Select filterOption={false} placeholder={t`add_vehicle_modal_field_immat_country_placeholder`} allowClear showSearch optionFilterProp="children">
                {state.countries.map((country, index) => {
                  return (
                    <Select.Option key={`country_${index}`} value={country.code}>
                      {country.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item name="plateNumber" rules={[{ required: true, message: "" }]}>
              <Input placeholder={t`add_vehicle_modal_field_immat_number_placeholder`} />
            </Form.Item>
          </>
        )}

        {state.formError != null && <span className="formError">{state.formError}</span>}
      </Form>
    </Modal>
  );
};
