// Imports
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input } from "antd";
import { t } from "@lingui/macro";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import md5 from "md5";

// Components
import MainLayout from "components/templates/MainLayout";
import { RoundedButton } from "components/RoundedButton";

// Constants
import { SiteRoutes } from "config/constants";

// API
import { login } from "api/justbipApi";
import { useAuth } from "hooks/auth";

// Define main state type
type LoginState = {
  formProcessing: boolean;
  formError: string | null;
};

// Main element
function Login() {
  // Define state variables
  const [state, setState] = React.useState<LoginState>({ formProcessing: false, formError: null });

  // Define form variable
  const [form] = Form.useForm();

  // Use some hooks
  const navigate = useNavigate();
  const location = useLocation();
  let auth = useAuth();

  //
  // Run this at the page mount
  //
  useEffect(() => {
    // If the user is already authenticated, then redirect to user profile
    if (auth.isAuthenticated()) {
      console.log("Redirect to user");
      navigate(SiteRoutes.USER_PROFILE);
    }
  }, [auth, navigate]);

  //
  // UI Actions
  //
  const onPressSubmit = () => {
    form.submit();
  };

  const onLoginSubmit = () => {
    setState((prevState) => ({ ...prevState, formProcessing: true, formError: null }));

    // Validate the form
    form
      .validateFields()
      .then((values) => {
        // Call the login API
        login(values.mail, md5(values.password))
          .then((userLogged) => {
            if (userLogged) {
              if (location.state !== null) {
                const { from } = location.state as any;

                // Check if there is a "from" path in location state
                if (from) {
                  // Then redirect to it if exists
                  navigate(from);
                  return;
                }
              }

              // Else, we redirect to user profile
              navigate(SiteRoutes.USER_PROFILE);
            } else {
              setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`login_form_error` }));
            }
          })
          .catch(() => {
            setState((prevState) => ({ ...prevState, formProcessing: false, formError: t`login_form_error` }));
          });
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, formProcessing: false, formError: "Form not valid" }));
      });
  };

  //
  // Main Render
  //
  return (
    <MainLayout title={t`login_view_meta_title`} description={t`login_view_meta_description`} withBackground={true}>
      <div className="notConnectedContainer">
        <div className="formContainer">
          <div className="formContent">
            <span className="formTitle">{t`login_form_title`}</span>
            <Form name="login" form={form} className="formContent" wrapperCol={{ span: 24 }} onFinish={onLoginSubmit}>
              <Form.Item name="mail" rules={[{ required: true, type: "email", message: "" }]}>
                <Input prefix={<UserOutlined />} placeholder={t`global_form_field_mail_placeholder`} />
              </Form.Item>

              <Form.Item name="password" rules={[{ required: true, message: "" }]}>
                <Input.Password prefix={<LockOutlined />} placeholder={t`global_form_field_password_placeholder`} />
              </Form.Item>

              <RoundedButton className={`submitButton`} enabled={!state.formProcessing} loading={state.formProcessing} onClick={() => onPressSubmit()} text={t`login_form_main_cta`} />

              {state.formError != null && <span className="formError">{state.formError}</span>}

              <a className="forgotPassword" href={SiteRoutes.FORGOT_PASSWORD}>{t`login_form_forgot_password_cta`}</a>
            </Form>
          </div>
          <div className="formFooter">
            <span>
              {t`login_form_no_account`}&nbsp;&nbsp;<a href={SiteRoutes.SIGNUP}>{t`login_form_no_account_link`}</a>
            </span>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Login;
