//
// Custom route component that extends the react route and add two new props:
// - title: Page title
// - authenticated: Boolean used to secure the page to only authenticated users
//

// Imports
import { Navigate, useLocation } from 'react-router-dom';

// Hooks
import { useAuth } from 'hooks/auth';

// Config
import { SiteRoutes } from 'config/constants'

//
// Core
//
function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
  
    if (!auth.isAuthenticated()) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to={SiteRoutes.LOGIN} state={{ from: location }} replace />;
    }
  
    return children;
}

export default RequireAuth;