// Imports
import { useEffect, useState } from "react";
import { t } from "@lingui/macro"
import { Carousel, Col, Row, Spin, Tooltip } from "antd";
import ReactPlayer from 'react-player'
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

// Components
import MainLayout from 'components/templates/MainLayout'
import { SearchBar } from "components/SearchBar";

// Constants
import { DEFAULT_LAYOUT_GRID, SiteRoutes } from "config/constants"

// API
import {getPois} from 'api/justbipLegacyApi'

// Hooks
import useWindowDimensions from "hooks/windowDimensions";

// Load home medias
import HomepageBloc2 from 'assets/images/homepage_bloc_2.svg'
import StickerJustbipAssistance from 'assets/images/sticker_justbip_assistance.png'
import HandicapParking from 'assets/images/handicap_parking.png'
import ChevronLeft from 'assets/images/ic-chevron-2.svg'
import ChevronRight from 'assets/images/ic-chevron-1.svg'

// Types
import { Partner } from "ts/interfaces/Poi";
// Define main state type
type HomeState = {
    partners?: Partner[],
}


//
// Main element
//
function Home() {

    // Define state variables
    const [state, setState] = useState<HomeState>({})

    // Hooks
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    //
    // Run this at the page mount
    //
    useEffect(() => {
        // Retrieve POIs list from API
        getPois().then((pois) => {
            let partners = pois.map(p => p.partner).filter((v,i,a)=>a.findIndex(v2=> (v2.name === v.name)) === i )
            
            // Move total in first position
            partners.sort((a, b) => {
                if(a.name === "TotalEnergies") return -1;
                else return 1
            })

            // Save partners
            setState(prevState => ({ ...prevState, partners}))
        })
    }, [])
    
    //
    // UI Callbacks
    //
    const onPoiSearch = (searchText: string) => {
        navigate(SiteRoutes.PLAN, {state: {searchText}})
    }

    //
    // Main Render
    //
    return (
        <MainLayout title={t`homepage_view_meta_title`} description={t`homepage_view_meta_description`}>
            <div id="homepage">
                <Row align="middle">
                    <Row className="topBlocRow">
                        <Col {...DEFAULT_LAYOUT_GRID}>
                            <div className="topBloc">
                                <div className="left">
                                    <h1 className="title">{t`homepage_view_top_bloc_title`}</h1>
                                    <span className="desc">{t`homepage_view_top_bloc_description`}</span>
                                </div>
                                <div className="right">
                                    <div className="videoContainer">
                                        <ReactPlayer className="player" width={260} height={462} url={"https://dev.justbip.com/static_medias/homepage.mp4"} muted={true} playing={true} loop={true} playsinline={true} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Col {...DEFAULT_LAYOUT_GRID}>
                        <div className="searchContainer">
                            <Row align="middle">
                                <Col xs={{span: 22, offset:1}} lg={{span: 18, offset: 3}}>
                                    <SearchBar searchText="" onEnterInput={onPoiSearch} placeholder={t`map_search_placeholder`} />
                                </Col>
                            </Row>
                        </div>
                        <div className="homepageBloc">
                            <Row align="middle">
                                <Col xs={24} md={12}>
                                    <span className="title">{t`homepage_view_bloc_2_title`}</span>
                                    <span className="desc">{t`homepage_view_bloc_2_description`}</span>
                                </Col>
                                <Col className="imageContainer" xs={24} md={12}>
                                    <img src={StickerJustbipAssistance} alt="bloc_2" />
                                </Col>
                            </Row>
                        </div>
                        <div className="homepageBloc bloc3">
                            <Row align="middle">
                                <Col className="imageContainer" xs={24} md={12}>
                                    <img src={HomepageBloc2} alt="bloc_3" />
                                </Col>
                                <Col xs={24} md={12}>
                                    <span className="title">{t`homepage_view_bloc_3_title`}</span>
                                    <span className="desc">{t`homepage_view_bloc_3_description`}</span>
                                </Col>
                            </Row>
                        </div>
                        <div className="homepageBloc">
                            <Row align="middle">
                                <Col xs={24} md={12}>
                                    <span className="title">{t`homepage_view_bloc_4_title`}</span>
                                    <span className="desc">{t`homepage_view_bloc_4_description`}</span>
                                </Col>
                                <Col className="imageContainer" xs={24} md={12}>
                                    <img src={HandicapParking} alt="bloc_3" style={{maxWidth: 400, borderRadius:15}} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row className="partnersBloc">
                    <Col {...DEFAULT_LAYOUT_GRID}>
                        <span className="title">{t`homepage_view_partners_bloc_title`}</span>

                        {!state.partners && 
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
                        }

                        <Carousel slidesToShow={width > 700 ? 5 : (width > 400 ? 3 : 2)} slidesToScroll={1} dots={false} arrows={true} prevArrow={<img className="chevron chevronLeft" src={ChevronLeft} alt="Previous" />} nextArrow={<img className="chevron chevronRight" src={ChevronRight} alt="Next" />}>
                            {state.partners?.map((partner, index) => {
                                return (
                                    <div className="partner" key={`partner_${index}`}>
                                        <Tooltip title={partner.name}>
                                            <img src={partner.imageUrl} alt={partner.name} />
                                        </Tooltip>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    )
}

export default Home;