import { AdvancedMarker, CollisionBehavior, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import slug from "slug";

// Types
import { Poi } from "ts/interfaces/Poi";

// Define props type
interface MarkerProps {
  title: string;
  position: google.maps.LatLngLiteral;
  poi: Poi;
  visible: boolean;
}

export const PoisMapMarker: React.FC<MarkerProps> = ({ title, position, poi, visible }) => {
  const navigate = useNavigate();
  const [markerRef, marker] = useAdvancedMarkerRef();

  // When ready, init tooltip on mouse over
  useEffect(() => {
    if (!marker || !visible) return;

    // Display info windows on marker hover
    var infowindow = new google.maps.InfoWindow({
      content: `
        <div class="poisMapInfoWindow">
            <div class="left">
                <img src="${poi.partner.imageUrl}" alt="${poi.partner.name}" />
            </div>
            <div class="middle">
                <span class="name">${poi.name}</span>

                <div class="category">
                    <span class="label">${poi.category.name}</span>
                    <img src="${poi.category.imageUrl}" />
                </div>

                <div class="addressContainer">
                    <span>${poi.address}</span>
                    <span>${poi.zipcode} ${poi.city}</span>
                </div>
            </div>
        </div>
      `,
    });

    const onMouseOver = () => infowindow.open(marker.map, marker);
    const onMouseOut = () => infowindow.close();

    marker.content?.addEventListener("mouseover", onMouseOver);
    marker.content?.addEventListener("mouseout", onMouseOut);

    return () => {
      marker.content?.removeEventListener("mouseover", onMouseOver);
      marker.content?.removeEventListener("mouseout", onMouseOut);
    };
  }, [navigate, poi, marker, visible]);

  return (
    <AdvancedMarker ref={markerRef} position={position} onClick={visible ? () => navigate(`/poi/${slug(poi.name)}/${poi.id}`) : undefined} collisionBehavior={visible ? CollisionBehavior.REQUIRED : CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY}>
      <img src={poi.category.imageUrl} width={20} height={20} alt={poi.category.name} style={{ opacity: visible ? 1 : 0.2 }} />
    </AdvancedMarker>
  );
};
