// Imports
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';
import { t } from "@lingui/macro"
import { UserOutlined } from '@ant-design/icons';

// Components
import MainLayout from 'components/templates/MainLayout'
import { RoundedButton } from 'components/RoundedButton';

// Constants
import { SiteRoutes } from 'config/constants';

// API 
import { forgotPassword } from 'api/justbipLegacyApi';
import { useAuth } from 'hooks/auth';

// Load images
import RequestCompletedGif from 'assets/gif/request_completed.gif'

// Define main state type
type ForgotPasswordState = {
    formProcessing: boolean,
    formError: string | null,

    requestSuccess: boolean
}

// Main element
function ForgotPassword() {
    // Define state variables
    const [state, setState] = React.useState<ForgotPasswordState>({ formProcessing: false, formError: null, requestSuccess: false });

    // Define form variable
    const [form] = Form.useForm();

    // Use some hooks
    const navigate = useNavigate();
    let auth = useAuth();

    //
    // Run this at the page mount
    //
    useEffect(() => {
        // If the user is already authenticated, then redirect to user profile
        if(auth.isAuthenticated()) {
            console.log("Redirect to user")
            navigate(SiteRoutes.USER_PROFILE)
        }
    }, [auth, navigate])

    //
    // UI Actions
    //
    const onPressSubmit = () => {
        form.submit();
    }

    const onLoginSubmit = () => {
        setState(prevState => ({ ...prevState, formProcessing: true, formError: null }))

        // Validate the form
        form.validateFields()
			.then((values) => {
                // Call the login API
                forgotPassword(values.mail).then((resultSuccess) => {
                    if(resultSuccess) 
                    { 
                        setState(prevState => ({ ...prevState, formProcessing: false, requestSuccess: true }))
                    }
                    else {
                        setState(prevState => ({ ...prevState, formProcessing: false, formError: t`forgot_password_form_error` }))
                    }
                })
                .catch(() => {
                    setState(prevState => ({ ...prevState, formProcessing: false, formError: t`forgot_password_form_error` }))
                })
			})
			.catch(() => {
                setState(prevState => ({ ...prevState, formProcessing: false, formError: "Form not valid" }))
            });
    }
    

    //
    // Main Render
    //
    return (
        <MainLayout title={t`forgot_password_view_meta_title`} description={t`forgot_password_view_meta_description`} withBackground={true}>
            <div className="notConnectedContainer">
                <div className="formContainer">
                    <div className="formContent">
                        <span className="formTitle">{t`forgot_password_form_title`}</span>

                        {state.requestSuccess === false && 
                            <Form
                                name="login"
                                form={form}
                                className="formContent"
                                wrapperCol={{ span: 24 }}
                                onFinish={onLoginSubmit}
                            >
                                <Form.Item
                                    name="mail"
                                    rules={[{ required: true, type: "email", message: '' }]}
                                >
                                    <Input prefix={<UserOutlined />} placeholder={t`global_form_field_mail_placeholder`} />
                                </Form.Item>

                                <RoundedButton className={`submitButton`} enabled={!state.formProcessing} loading={state.formProcessing} onClick={() => onPressSubmit()} text={t`forgot_password_form_main_cta`} />

                                {state.formError != null && <span className="formError">{state.formError}</span>}
                            </Form>
                        }

                        {state.requestSuccess && 
                            <div className="forgotPasswordSuccess">
                                <img className="statusAnimation" src={RequestCompletedGif} alt="completed-img" />
                                <span>{t`forgot_password_form_success`}</span>
                            </div>
                        }
                    </div>
                    <div className="formFooter">
                        <span>{t`forgot_password_form_no_account`}&nbsp;&nbsp;<a href={SiteRoutes.SIGNUP}>{t`forgot_password_form_no_account_link`}</a></span>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default ForgotPassword;