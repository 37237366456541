// Imports
import { Component, ReactNode } from 'react'; 
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Define props type
type RoundedButtonProps = {
    text: string,
    onClick: Function
    enabled?: boolean,
    loading?: boolean,
    className?: string,

    icon?: ReactNode,
    secondaryColor?: boolean
}

// Define component
export class RoundedButton extends Component<RoundedButtonProps, {}> {
    static defaultProps = {
        text: '',
        enabled: true,
        loading: false,
        className: '',
        icon: null,
        secondaryColor: false
    }

    // render will know everything!
    render() {
        const {text, icon, enabled, loading, secondaryColor, className, onClick} = this.props;

        return (
            <div className={`roundedButton ${!enabled ? "disabled " : ""}${!enabled ? "loading " : ""}${secondaryColor ? " secondary": ""} ${className}`} onClick={() => onClick()}>
                {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#FFF" }} spin />} />}
                {!loading && icon !== null && icon}
                <span className={`label${!loading && icon !== null ? ' leftMargin': ''}`}>{text}</span>
            </div>
        )
    }
}