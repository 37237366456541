const config: any = {
  local: {
    justbipApi: {
        url: process.env.REACT_APP_JUSTBIP_NEW_API_URL,
        endpoints: {
          getUserProfile: "/user",
          updateUser: "/user/update",
          getUserVehicles: "/user/vehicles",
          login: "/user/login",

          updateVehicle: "/user/vehicle/{vehicleId}/update",
          deleteVehicle: "/user/vehicle/{vehicleId}/delete",

          cancelAssistance: "/user/assistance/{assistanceId}/cancel",
          cancelAllAssistance: "/user/assistance/cancel_all",
          updateAssistanceVehicle: "/user/assistance/{assistanceId}/vehicle/{userVehicleId}/set",

          findPoisNearAnItinerate: "/poi/find_pois_near_itinerate",
        }
    },
    justbipLegacyApi: {
      url: "https://www.justbip.com/webservices/f7c266e912c797f104bf0cf250c89b298e1d645e",
      endpoints: {
        login: "/user/{login}/{password}/login.json",
        signup: "/user/signup.json",
        userProfile: "/user/{userId}/{token}/profile.json",
        forgotPassword: "/user/password/forgot.json",
        changePassword: "/user/password/change.json",

        favoritesList: "/poi/{userId}/{token}/listFav.json",
        addFavorite: "/poi/{userId}/{token}/{poiId}/addFav.json",
        deleteFavorite: "/poi/{userId}/{token}/{poiId}/delFav.json",

        poisList: "/poi/list.json",
        poiDetails: "/poi/{poiId}.json",

        vehicleBrands: "/vehicle/{type}/list.json",
        vehicleModels: "/vehicle/{type}/{brand}/list.json",
        vehicleColors: "/colors/list.json",
        addVehicle: "/vehicle/{userId}/{token}/add.json",

        countriesList: "/country/2/list.json",
        pmrTypesList: "/PMR/typelist.json",

        assistanceAsk: "/assistance/{userId}/{token}/{jbcode}/{place}/{subPlace}ask.json",
        assistanceCheck: "/assistance/{userId}/{token}/{assistanceId}.json",
        assistanceRetry: "/assistance/{userId}/{token}/{assistanceId}/renew.json",
        assistanceValidate: "/assistance/{userId}/{token}/{assistanceId}/{rating}/validate.json",
      },
    },
    google: {
      mapsApiKey: process.env.REACT_APP_JUSTBIP_GOOGLE_MAPS_API_KEY,
      mapsApiId: process.env.REACT_APP_JUSTBIP_GOOGLE_MAPS_MAP_ID,
      recaptchaClientKey: process.env.REACT_APP_JUSTBIP_GOOGLE_RECAPTCHA_CLIENT_KEY,
      recaptchaSecretKey: process.env.REACT_APP_JUSTBIP_GOOGLE_RECAPTCHA_SECRET_KEY,
    },
    auth: {
      encryptionKey: process.env.REACT_APP_JUSTBIP_AUTH_ENCRYPTION_KEY,
    },
    assistanceRetryThreshold: 60 * 4, // in minutes
  },
};

export default config;
