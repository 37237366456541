import moment from "moment";

export class AssistanceRequest {
    id: number;
    poiId: number;
    cycleId: number;
    status: number;
    createdAt: moment.Moment;
    closedAt: moment.Moment | null;
    isClosed: boolean;

    constructor(apiResult: any) {
        this.id = parseInt(apiResult.id);
        this.poiId = parseInt(apiResult.id_poi);
        this.cycleId = parseInt(apiResult.id_cycle);
        this.status = parseInt(apiResult.status);

        this.closedAt = apiResult.closed ? moment(apiResult.closed, "YYYY-MM-DD HH:mm:ss") : null;

        this.createdAt = moment(apiResult.created, "YYYY-MM-DD HH:mm:ss")

        this.isClosed = this.closedAt && this.createdAt ? this.closedAt.isAfter(this.createdAt) : false
    }
}