// Define all classes and subclasses needed for POI definition
import PartnerTotalBanner from 'assets/images/partners/total.png'

export class Partner {
    name: string;
    imageUrl: string;
    bannerImageUrl: string;

    constructor(name: string, image: string) {
        this.name = name;

        this.imageUrl = image.replace("http:", "https:")

        if(name === "TotalEnergies")
            this.bannerImageUrl = PartnerTotalBanner;
        else
            this.bannerImageUrl = this.imageUrl;
    }
}

export class Category {
    name: string;
    imageUrl: string;

    constructor(name: string, image: string) {
        this.name = name;
        this.imageUrl = image.replace("http:", "https:");
    }
}

class Coordinates {
    latitude: number;
    longitude: number;

    constructor(lat: number, lng: number) {
        this.latitude = lat;
        this.longitude = lng;
    }
}

export class PoiPlace {
    id: number;
    label: string;

    subPlaces: PoiPlace[];

    constructor(apiResult: any) {
        this.id = apiResult.id;
        this.label = apiResult.label
        this.subPlaces = []

        if(apiResult.details !== undefined) 
        {
            apiResult.details.forEach((item: any) => {
                this.subPlaces.push(new PoiPlace(item))
            })
        }
    }
}

export class Poi {
    id: number;
    jbcode: number;
    name: string;
    partner: Partner;
    category: Category;

    address: string;
    city: string;
    zipcode: string;

    location?: Coordinates;

    userDistance?: number;

    // Details
    open?: boolean;
    phone?: string;
    hoursDetails?: string;
    serviceDetails?: string;

    places: PoiPlace[];

    constructor(apiResult: any) {
        this.id = parseInt(apiResult.id);
        this.jbcode = parseInt(apiResult.jbcode);
        this.name = apiResult.name;
        this.partner = new Partner(apiResult.partner, apiResult.partnerlogo);
        this.category = new Category(apiResult.cat, apiResult.catlogo);

        this.address = apiResult.adr;
        this.city = apiResult.city;
        this.zipcode = apiResult.zip;

        const coords = apiResult.coords;
        if(coords !== null && coords.length > 0) {
            this.location = new Coordinates(parseFloat(coords[0].lat), parseFloat(coords[0].long))
        }

        // Details
        this.open = apiResult?.open;
        this.phone = apiResult?.phone;
        this.hoursDetails = apiResult?.hours?.replaceAll('[br]', '\n');
        this.serviceDetails = apiResult?.txt?.replaceAll('[br]', '\n');

        this.places = []
        apiResult.places?.forEach((item: any) => {
            this.places.push(new PoiPlace(item))
        })
    }
}