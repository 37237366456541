export const SiteRoutes = {
    HOME: "/",
    PLAN: "/plan",
    
    USER_PROFILE: "/user",
    EDIT_USER_PROFILE: "/edit_user",

    POI: "/poi/:poiName/:poiId",
    ASSISTANCE: "/poi/:poiName/:poiId/assistance",

    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password/:userId/:emailToken",
    SIGNUP: "/signup"
}

export const LOCATION_SEARCH_COOKIE_NAME = "smileeLS"

export const assistance_request_status = {
    UNDERTERMINED: 0,
    SENDING: 1,
    LOADING: 2,
    WAITING: 4,
    SUCCESS: 3,
    FAIL: 5
};

export const DEFAULT_LAYOUT_GRID = {
    xs: {span: 24, offset: 0},
    sm: {span: 24, offset: 0},
    md: {span: 22, offset: 1},
    lg: {span: 22, offset: 1},
    xl: {span:20, offset: 2},
    xxl: {span: 16, offset: 4}
}