// Imports
import React, { useEffect, useState } from "react";
import { Modal, Slider, Spin, Tooltip, notification } from "antd";
import { t } from "@lingui/macro";
import { ArrowDownOutlined, LoadingOutlined } from "@ant-design/icons";
import GooglePlacesAutocomplete, { geocodeByLatLng, geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";

// Helpers
import { Poi } from "ts/interfaces/Poi";
import { findPoidsNearAnItinerate } from "api/justbipApi";

// Load images
import GeolocationIcon from "assets/images/geolocation_icon.png";

// Constants
import { UserPosition } from "helpers/geolocation";
import { useUserLocation } from "hooks/userLocation";

// Define props type
type SimulateItineraryModalProps = {
  visible: boolean;
  pois: Poi[];

  onSimulationValidation: (directionsResult: google.maps.DirectionsResult, poisOnItinerary: number[]) => void;
  onClose: () => void;
};

// Define stats type
type SimulateItineraryModalState = {
  start_inputText: string;
  start_selectedGooglePlace?: any;
  start_selectedPosition?: UserPosition;

  end_inputText: string;
  end_selectedGooglePlace?: any;
  end_selectedPosition?: UserPosition;

  geolocationProcessing: boolean;
  simulationLoading: boolean;

  distanceThresholdIndex: number;
};

enum PositionType {
  Start = 1,
  End = 2,
}

const ALLOWED_DISTANCE_THRESHOLDS_IN_METERS = [200, 500, 1000, 2000, 5000, 10000, 20000];

export const SimulateItineraryModal: React.FC<SimulateItineraryModalProps> = ({ visible, pois, onSimulationValidation, onClose }) => {
  // Define state variables
  const [state, setState] = useState<SimulateItineraryModalState>({ start_inputText: "", end_inputText: "", geolocationProcessing: false, simulationLoading: false, distanceThresholdIndex: 1 });

  // Hooks
  const userLocation = useUserLocation();

  // Use Effects
  useEffect(() => {
    if(!visible) return;

    // Use last user psoition 
    const userPosition = userLocation.getLastUserPosition();
    if(userPosition) {
      setState((prevState) => ({
        ...prevState,
        start_selectedGooglePlace: { label: userPosition.label, value: userPosition },
        start_selectedPosition: userPosition
      }));
    }
  }, [visible])

  //
  // UI Actions
  //

  const onInputChange = (e: string, type: PositionType) => {
    setState((prevState) => ({ ...prevState, [`${type === PositionType.Start ? "start" : "end"}_inputText`]: e }));
  };

  const onSelectPlace = (e: any, type: PositionType) => {
    geocodeByPlaceId(e.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setState((prevState) => ({
          ...prevState,
          [`${type === PositionType.Start ? "start" : "end"}_selectedGooglePlace`]: e,
          [`${type === PositionType.Start ? "start" : "end"}_selectedPosition`]: { label: e.label, place_id: e.value.place_id, location: { lat, lng } },
        }));
      });
  };

  const onSubmitAddress = async () => {
    if (state.start_selectedPosition && state.end_selectedPosition) {
      setState((prevState) => ({ ...prevState, simulationLoading: true }));

      const directionsRequest: google.maps.DirectionsRequest = {
        origin: state.start_selectedPosition.location,
        destination: state.end_selectedPosition.location,
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: google.maps.TrafficModel.OPTIMISTIC,
        },
      };

      // Use Google APIs to get directions result (usable on a map)
      try {
        const directionsService = new google.maps.DirectionsService();
        const result = await directionsService.route(directionsRequest);
        if (result) {
          // Find pois on the itinerary
          const poiIds: number[] = pois.map((p) => p.id);
          const polylineCoordinates = result.routes[0]?.overview_path.map((c) => [c.lat(), c.lng()]);

          const result2 = await findPoidsNearAnItinerate(poiIds, polylineCoordinates, ALLOWED_DISTANCE_THRESHOLDS_IN_METERS[state.distanceThresholdIndex]);

          // Trigger callback
          onSimulationValidation(result, result2);

          // Close modal
          setState((prevState) => ({ ...prevState, simulationLoading: false }));
          onClose();
        }
      } catch (exc) {
        console.log(exc);
        setState((prevState) => ({ ...prevState, simulationLoading: false }));
        notification.error({ message: t`simulate_itinerary_directions_api_error` });
      }
    } else {
      notification.error({ message: t`simulate_itinerary_addresses_required_error` });
    }
  };

  //
  // Geolocation callbacks
  //

  // Retrieve user position from browser
  const getUserPosition = () => {
    if ("geolocation" in navigator) {
      setState((prevState) => ({ ...prevState, geolocationProcessing: true }));
      navigator.geolocation.getCurrentPosition(geolocSuccess, geolocError, { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 });
    }
  };

  const geolocSuccess = (position: GeolocationPosition) => {
    if (position) {
      setTimeout(() => {
        const lagLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        geocodeByLatLng(lagLng)
          .then((results) => {
            if (results.length > 0) {
              const place = results[0];
              setState((prevState) => ({
                ...prevState,
                start_selectedGooglePlace: { label: place.formatted_address, value: place },
                start_selectedPosition: { label: place.formatted_address, place_id: place.place_id, location: { lat: position.coords.latitude, lng: position.coords.longitude } },
                geolocationProcessing: false,
              }));
            }
          })
          .catch((error) => console.error(error));
      }, 500);
    }
  };

  const geolocError = () => {
    setState((prevState) => ({ ...prevState, geolocationProcessing: false }));
  };

  //
  // Render
  //
  return (
    <Modal
      title={t`simulate_itinerary_address_modal_title`}
      visible={visible}
      className="addressFormModal"
      cancelButtonProps={{ style: { display: "none" } }}
      okText={state.simulationLoading ? t`simulate_itinerary_address_modal_simulation_running` : t`simulate_itinerary_address_modal_cta`}
      onOk={() => onSubmitAddress()}
      okButtonProps={{
        disabled: state.simulationLoading,
        className: "modalSubmitButton",
      }}
      onCancel={onClose}
      bodyStyle={{ padding: 16, textAlign: "left" }}
      centered
    >
      <span className="desc">{t`simulate_itinerary_address_modal_desc`}</span>

      <div className="inputsContainer">
        <GooglePlacesAutocomplete
          apiOptions={{
            language: "fr",
            region: "fr",
          }}
          selectProps={{
            value: state.start_selectedGooglePlace ?? undefined,
            placeholder: t`simulate_itinerary_start_address`,
            onChange: (e: any) => onSelectPlace(e, PositionType.Start),
            onInputChange: (e: any) => onInputChange(e, PositionType.Start),

            menuIsOpen: state.start_inputText.length > 0,
          }}
          minLengthAutocomplete={1}
        />

        <Tooltip className="userPosition" title={t`user_location_address_modal_geolocation_tooltip`}>
          <button onClick={() => getUserPosition()}>
            {!state.geolocationProcessing && <img src={GeolocationIcon} alt="user location" />}
            {state.geolocationProcessing && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
          </button>
        </Tooltip>
      </div>

      <div className="itinerarySeparator">
        <ArrowDownOutlined />
      </div>

      <div className="inputsContainer">
        <GooglePlacesAutocomplete
          apiOptions={{
            language: "fr",
            region: "fr",
          }}
          selectProps={{
            placeholder: t`simulate_itinerary_end_address`,
            onChange: (e: any) => onSelectPlace(e, PositionType.End),
            onInputChange: (e: any) => onInputChange(e, PositionType.End),

            menuIsOpen: state.end_inputText.length > 0,
          }}
          minLengthAutocomplete={1}
        />
      </div>

      <div className="distanceThrehsoldContainer">
        <span className="title">{t`simulate_itinerary_settings_distance_threshold_title`}</span>
        <span className="desc">{t`simulate_itinerary_settings_distance_threshold_desc`}</span>
        <Slider
          value={state.distanceThresholdIndex}
          tooltipVisible={false}
          min={0}
          max={ALLOWED_DISTANCE_THRESHOLDS_IN_METERS.length - 1}
          onChange={(value) => setState((prevState) => ({ ...prevState, distanceThresholdIndex: value }))}
        />
        <span
          className="threhsold_label"
          dangerouslySetInnerHTML={{
            __html: t({
              id:
                ALLOWED_DISTANCE_THRESHOLDS_IN_METERS[state.distanceThresholdIndex] >= 1000
                  ? "simulate_itinerary_settings_distance_threshold_label_km"
                  : "simulate_itinerary_settings_distance_threshold_label_m",
              values: {
                distance:
                  ALLOWED_DISTANCE_THRESHOLDS_IN_METERS[state.distanceThresholdIndex] >= 1000
                    ? ALLOWED_DISTANCE_THRESHOLDS_IN_METERS[state.distanceThresholdIndex] / 1000
                    : ALLOWED_DISTANCE_THRESHOLDS_IN_METERS[state.distanceThresholdIndex],
              },
            }),
          }}
        />
      </div>
    </Modal>
  );
};
