// Imports
import React, { ReactNode, useEffect } from "react";
import { t } from "@lingui/macro";
import { Row, Col, Checkbox, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingOutlined, NodeIndexOutlined } from "@ant-design/icons";
import { LatLng } from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";
import { useLocation, useNavigate } from "react-router-dom";
import slug from "slug";

// Components
import MainLayout from "components/templates/MainLayout";
import { PoiListItem } from "components/PoiListItem";
import { PoisMap } from "components/PoisMap";
import { SearchBar } from "components/SearchBar";
import { SimulateItineraryModal } from "components/modals/SimulateItineraryModal";

// API
import { getPois, getUserFavorites } from "api/justbipLegacyApi";

// Helpers
import { loadConfig } from "helpers/global";
import { poisWithDistance, UserPosition } from "helpers/geolocation";

// Types
import { Category, Poi } from "ts/interfaces/Poi";
import { UserLocationForm } from "components/UserLocationForm";

// Hooks
import { useUserLocation } from "hooks/userLocation";

// Load images
import FilterIcon from "assets/images/ic-filter.svg";
import FilterIconSelected from "assets/images/ic-filter-selected.svg";
import { APIProvider } from "@vis.gl/react-google-maps";

// Load configuration
const config = loadConfig();

//
// Core
//

const NUMBER_OF_POIS_PER_PAGE = 10;

// Define main state type
type PlanState = {
  pois: Poi[]; // List of all POIs retrieved by API
  filteredData: Poi[]; // List of all POIs after filters
  displayedData: Poi[]; // List of all POIs displayed in the list (in order to optimize the view)

  userPosition: UserPosition | null;
  mapVisible: boolean;
  mapZoom: number;
  mapCenter: LatLng;

  loading: boolean;

  categories: Category[];
  selectedCategories: Category[];
  displayOnlyFavorites: boolean;

  filtersOpened: boolean;
  searchText: string;

  userFavorites: Poi[];

  displaySimulateItinerary: boolean;
  itineraryDirections: google.maps.DirectionsResult | null;
  poisOnItinerary: number[];
};

// Utils function
const getDefaultSearchText = (location: any): string => {
  // Check if there is a searchText in parameters
  if (location.state !== null) {
    const { searchText } = location.state as any;
    if (searchText) {
      return searchText;
    }
  }

  return "";
};

//
// Main element
//
function Plan() {
  // Hooks
  const userLocation = useUserLocation();
  const navigate = useNavigate();
  const location = useLocation();

  // Define state variables
  const [state, setState] = React.useState<PlanState>({
    pois: [],
    filteredData: [],
    displayedData: [],

    userPosition: userLocation.getLastUserPosition(),

    categories: [],
    selectedCategories: [],
    displayOnlyFavorites: false,

    mapVisible: true,
    mapZoom: 6,
    mapCenter: { lat: 46.52863469527167, lng: 2.43896484375 },

    loading: true,
    filtersOpened: false,
    searchText: getDefaultSearchText(location),

    userFavorites: [],

    displaySimulateItinerary: false,
    itineraryDirections: null,
    poisOnItinerary: [],
  });

  //
  // Run this at the page mount
  //
  useEffect(() => {
    // // Retrieve POIs list from API
    getPois().then((pois) => {
      const categories = pois.map((p) => p.category).filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);

      const filteredData = applyPoisFilters(poisWithDistance(pois, state.userPosition));

      setState((prevState) => ({ ...prevState, pois, filteredData, displayedData: filteredData.slice(0, NUMBER_OF_POIS_PER_PAGE), categories, loading: false }));
    });

    getUserFavorites().then((favorites) => {
      setState((prevState) => ({ ...prevState, userFavorites: favorites }));
    });

    // Check if there is a preloaded directions to apply
    try {
      const preloadedDirections_str = localStorage.getItem("justbip_preloaded_directions");
      if (preloadedDirections_str && preloadedDirections_str.length > 0) {
        const preloadedDirections = JSON.parse(preloadedDirections_str);
        if (preloadedDirections.result && preloadedDirections.poiIds) {
          setState((prevState) => ({ ...prevState, itineraryDirections: preloadedDirections.result, poisOnItinerary: preloadedDirections.poiIds }));
        }

        localStorage.removeItem("justbip_preloaded_directions");
      }
    } catch (exc) {
      localStorage.removeItem("justbip_preloaded_directions");
    }


    
    const userPosition = userLocation.getLastUserPosition();
    console.log("User location on load", userPosition);
  }, []);

  //
  // When POIs or filters changes, apply filter
  //
  useEffect(() => {
    let pois: Poi[] = poisWithDistance(state.pois, state.userPosition);
    pois = applyPoisFilters(pois);

    setState((prevState) => ({ ...prevState, filteredData: pois, displayedData: pois.slice(0, NUMBER_OF_POIS_PER_PAGE) }));
  }, [state.userPosition, state.searchText, state.selectedCategories.length, state.userFavorites, state.displayOnlyFavorites]);

  //
  // User distance related functions
  //

  // Address callbacks
  const onAddressValidation = (position: UserPosition) => {
    setState((prevState) => ({ ...prevState, userPosition: position, mapZoom: 13, mapCenter: { lat: position.location.lat, lng: position.location.lng } }));
  };

  //
  // UI Actions
  //
  const onPoiClick = (poi: Poi) => {
    navigate(`/poi/${slug(poi.name)}/${poi.id}`);
  };

  const onPoiMapSearchClick = (poi: Poi) => {
    setState((prevState) => ({ ...prevState, mapZoom: 13, mapCenter: { lat: poi.location!.latitude, lng: poi.location!.longitude } }));
  };

  const increaseListLength = () => {
    const newPoisList = state.filteredData.slice(0, state.displayedData.length + NUMBER_OF_POIS_PER_PAGE);

    setState((prevState) => ({ ...prevState, displayedData: newPoisList }));
  };

  //
  // POIs Filters
  //

  const onFiltersClick = () => {
    setState((prevState) => ({ ...prevState, filtersOpened: !state.filtersOpened }));
  };

  const onDisplayOnlyFavoritesClick = () => {
    setState((prevState) => ({ ...prevState, displayOnlyFavorites: !state.displayOnlyFavorites }));
  };

  const countFilters = (): number => {
    let nbFilters = 0;

    if (state.searchText != null && state.searchText.length > 0) {
      nbFilters++;
    }

    nbFilters += state.selectedCategories.length;

    if (state.displayOnlyFavorites) nbFilters++;

    return nbFilters;
  };

  const hasFilters = (): boolean => {
    return countFilters() > 0;
  };

  const resetFilters = () => {
    // Save state
    setState((prevState) => ({ ...prevState, selectedCategories: [], searchText: "", displayOnlyFavorites: false }));
  };

  const renderFiltersDescription = (): ReactNode => {
    if (hasFilters()) {
      return (
        <Row className="filters_count_container">
          <Col xs={24} style={{ flexGrow: 1 }}>
            <span className="results_count">{t({ id: "map_results_count", values: { count: state.filteredData.length } })}</span>
            <span className="filters_count">
              {t({ id: "map_filters_count", values: { count: countFilters() } })}
              <span
                className="reset_filters"
                onClick={() => {
                  resetFilters();
                }}
              >{t`map_filters_reset`}</span>
            </span>
          </Col>
        </Row>
      );
    }

    return null;
  };

  const applyPoisFilters = (pois: Poi[]): Poi[] => {
    if (pois == null || pois.length === 0 || countFilters() === 0) {
      return pois;
    }

    let filteredData = pois.filter((poi) => {
      if (
        (state.searchText !== "" && poi.name.toLowerCase().indexOf(state.searchText.toLowerCase()) === -1) ||
        (state.selectedCategories.length > 0 && state.selectedCategories.find((c) => c.name === poi.category.name) == null) ||
        (state.displayOnlyFavorites && state.userFavorites.length > 0 && state.userFavorites.find((uf) => uf.id === poi.id) === undefined)
      ) {
        return false;
      } else {
        return true;
      }
    });

    return filteredData;
  };

  // Select or unselect a filter
  const didSelectFilter = (category: Category) => {
    let updatedCategoriesState = state.selectedCategories;

    let existingEntryIndex = updatedCategoriesState.findIndex((c) => c.name === category.name);
    if (existingEntryIndex === -1) {
      updatedCategoriesState.push(category);
    } else {
      updatedCategoriesState.splice(existingEntryIndex, 1);
    }

    setState((prevState) => ({ ...prevState, selectedCategories: updatedCategoriesState }));
  };

  //
  // Elements rendering
  //

  const renderToggleFilters = () => {
    return (
      <div className={`search_filter_toggle${state.filtersOpened ? " active" : ""}`}>
        <div
          className="action"
          onClick={() => {
            onFiltersClick();
          }}
        >
          {state.filtersOpened && <img src={FilterIconSelected} alt="filter-opened" />}
          {!state.filtersOpened && <img src={FilterIcon} alt="filter-closed" />}
        </div>
      </div>
    );
  };

  const renderSimulateItineraryButton = () => {
    return (
      <div className={`simulate_itinerary_button`}>
        <div className="action" onClick={() => setState((prevState) => ({ ...prevState, displaySimulateItinerary: true }))}>
          <span className="text">{t`map_itinerary_button`}</span>
          <NodeIndexOutlined />
        </div>
      </div>
    );
  };

  const renderEndScrollComponent = () => {
    if (state.loading)
      return (
        <div style={{ textAlign: "center" }}>
          <b>{t`map_infinite_scroll_loading`}</b>
          <br />
          <br />
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      );
    else
      return (
        <div style={{ textAlign: "center" }}>
          <b>{t`map_infinite_scroll_bottom_text`}</b>{" "}
        </div>
      );
  };

  //
  // Main Render
  //
  return (
    <MainLayout title={t`plan_view_meta_title`} description={t`plan_view_meta_description`}>
      <Row className="planView">
        <Col className="listContainer" sm={24} md={24} lg={12}>
          <Row id="planListContainer">
            <Col style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <UserLocationForm onAddressValidation={(position) => onAddressValidation(position)} />
                <div className="itinerarySimulation">{renderSimulateItineraryButton()}</div>
              </div>

              <Row className="searchContainer" justify="start" align="middle">
                <Col sm={24} md={24} lg={24} style={{ flexGrow: 1 }}>
                  <SearchBar
                    searchText={state.searchText}
                    additionalClasses={`search_bar_small search_with_filters`}
                    placeholder={t`map_search_placeholder`}
                    onSearch={(searchText) => {
                      setState((prevState) => ({ ...prevState, searchText }));
                    }}
                  />

                  {renderToggleFilters()}
                </Col>
              </Row>

              {state.filtersOpened && (
                <Row>
                  <Col xs={24} style={{ flexGrow: 1 }}>
                    <div className={`map_filters`}>
                      <span className="top_title">
                        {t`map_filters`} {countFilters() > 0 ? `(${countFilters()})` : ""}
                      </span>
                      <div className="filters_cols" key={`filter-container`}>
                        <div className="filters_col">
                          <span className="col_title">{t`map_filter_pois_type`}</span>
                          <ul>
                            {state.categories.map((category, index) => {
                              return (
                                <li key={`filter-${index}`}>
                                  <Checkbox
                                    className="checkbox"
                                    value={category.name}
                                    defaultChecked={false}
                                    checked={state.selectedCategories.find((c) => c.name === category.name) != null}
                                    onChange={() => {
                                      didSelectFilter(category);
                                    }}
                                  >
                                    {category.name}
                                  </Checkbox>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="filters_col">
                          <span className="col_title">{t`map_filter_favorites`}</span>
                          <ul>
                            <li>
                              <Checkbox
                                className="checkbox"
                                value={state.displayOnlyFavorites}
                                defaultChecked={false}
                                checked={state.displayOnlyFavorites}
                                onChange={onDisplayOnlyFavoritesClick}
                              >{t`map_filter_favorites_checkbox`}</Checkbox>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {renderFiltersDescription()}

              <InfiniteScroll
                dataLength={state.displayedData.length}
                next={increaseListLength}
                hasMore={state.displayedData.length < state.filteredData.length}
                loader={""}
                endMessage={renderEndScrollComponent()}
                scrollableTarget="planListContainer"
              >
                {state.displayedData.map((poi) => {
                  const isFavorite = state.userFavorites.find((uf) => uf.jbcode === poi.jbcode) ? true : false;

                  return (
                    <PoiListItem
                      key={`poi_${poi.id}`}
                      poi={poi}
                      onClick={() => {
                        onPoiClick(poi);
                      }}
                      onClickMapSearch={() => {
                        onPoiMapSearchClick(poi);
                      }}
                      isFavorite={isFavorite}
                    />
                  );
                })}
              </InfiniteScroll>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={0}>
          <div className="mapContainer">
            <APIProvider apiKey={config.google.mapsApiKey} libraries={["places"]}>
              <PoisMap center={state.mapCenter} zoom={state.mapZoom} data={state.filteredData} itineraryDirections={state.itineraryDirections} poisOnItinerary={state.poisOnItinerary} />
            </APIProvider>
          </div>
        </Col>
      </Row>
      <SimulateItineraryModal
        visible={state.displaySimulateItinerary}
        pois={state.filteredData}
        onClose={() => setState((prevState) => ({ ...prevState, displaySimulateItinerary: false }))}
        onSimulationValidation={(directionsResult, poiIds) => setState((prevState) => ({ ...prevState, itineraryDirections: directionsResult, poisOnItinerary: poiIds }))}
      />
    </MainLayout>
  );
}

export default Plan;
